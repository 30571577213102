import { css } from '@emotion/react'
import { Link } from 'gatsby'
import { useEffect } from 'react'
import { useInView } from 'react-intersection-observer'

import { mq } from '@/theme/mixins'
import { colors } from '@/theme/variables'

import { useMissionScrollContext } from '../../contexts/missionScrollContext'

export const TextOutro = (): JSX.Element => {
  const { addViewZone, removeViewZone } = useMissionScrollContext()
  const { inView: inView22, ref: ref22 } = useInView()
  const { inView: inView23, ref: ref23 } = useInView()

  useEffect(() => {
    inView22 ? addViewZone(22) : removeViewZone(22)
    inView23 ? addViewZone(23) : removeViewZone(23)
  }, [inView22, inView23, addViewZone, removeViewZone])
  const styles = {
    section: css`
      box-sizing: border-box;
      display: grid;
      padding: 0 var(--margin);
      justify-items: center;
      color: #fff;
    `,
    zone: css`
      grid-column: 1 / -1;
      height: max(8rem, 16vh);
    `,
    blockWrap: css`
      grid-column: 1 / -1;
      display: flex;
      align-items: center;
      text-align: center;
      justify-content: center;
      padding: 10vh var(--margin) calc(25vh - 1em);
      box-sizing: border-box;

      font-size: var(--fs-60);
      font-family: var(--ff-display);
      ${mq().ml} {
        font-size: var(--fs-48);
      }
      /* ${mq().ms} {
        font-size: var(--fs-36);
      } */
      ${mq().s} {
        flex-direction: column;
        padding-bottom: calc(50vh - 2em);
      }
      a {
        display: inline-block;
        color: inherit;
        text-decoration: underline;
        text-decoration-thickness: 2px;
        text-underline-offset: 0.125em;
        transition: color 300ms ease;

        text-transform: uppercase;

        ${mq().ms} {
          text-decoration-thickness: 1.5px;
        }
        @media (hover: hover) {
          &:hover {
            color: ${colors.red};
          }
        }
      }
      span {
        display: inline-block;
        height: 1em;
        width: 3px;
        background: ${colors.red};
        margin: 0 0.75em;
        ${mq().ms} {
          width: 2px;
        }
        ${mq().s} {
          height: 2px;
          width: 1em;
          margin: 0.75em 0;
        }
      }
    `,
  }
  return (
    <section css={styles.section}>
      <div
        ref={ref22}
        css={styles.zone}
      />
      <div
        css={styles.blockWrap}
        ref={ref23}
      >
        <Link to="#">Back to Top</Link>
        {/* <span />
        <a
          href="https://www.brearley.org"
          target="_blank"
          rel="noreferrer"
        >
          brearley.org
        </a> */}
      </div>
    </section>
  )
}
