import {
  Dispatch,
  ReactNode,
  createContext,
  useCallback,
  useContext,
  useState,
} from 'react'

interface IContext {
  viewZones: number[]
  addViewZone: Dispatch<number>
  removeViewZone: Dispatch<number>
  clearViewZones: () => void
}

const MissionScrollContext = createContext<IContext | undefined>(
  undefined
)

export const useMissionScrollContext = () => {
  return useContext(MissionScrollContext) as IContext
}

export const MissionScrollContextProvider = ({
  children,
}: {
  children: ReactNode
}): JSX.Element => {
  const [viewZones, setViewZones] = useState<number[]>([])
  const clearViewZones = useCallback(() => {
    setViewZones([])
  }, [])
  return (
    <MissionScrollContext.Provider
      value={{
        viewZones,
        addViewZone: (zone: number) =>
          setViewZones(prev => {
            if (!prev.includes(zone)) {
              return [...prev, zone]
            }
            return prev
          }),
        removeViewZone: (zone: number) =>
          setViewZones(prev => {
            if (prev.includes(zone)) {
              return prev.filter(prevZone => prevZone !== zone)
            }
            return prev
          }),
        clearViewZones: clearViewZones,
      }}
    >
      {children}
    </MissionScrollContext.Provider>
  )
}
