import { graphql } from 'gatsby'
import { ComponentPropsWithoutRef } from 'react'

import { DatoLinkIcon } from './DatoLinkIcon'

interface Props extends ComponentPropsWithoutRef<'a'> {
  data?: Queries.ExternalLinkFragment | null
  showIcon?: boolean
}

export const ExternalLink = ({
  data,
  showIcon,
  ...props
}: Props): JSX.Element => {
  return (
    <a
      href={data?.url || undefined}
      target="_blank"
      rel="noreferrer"
      {...props}
    >
      <span>{data?.linkText}</span>
      {showIcon && <DatoLinkIcon iconType="EXTERNAL" />}
    </a>
  )
}

export const ExternalLinkFragment = graphql`
  fragment ExternalLink on DatoCmsExternalLink {
    __typename
    id: originalId
    linkText
    url
  }
`
