import { graphql } from 'gatsby'
import { ComponentPropsWithoutRef } from 'react'

import { DatoLink } from '../DatoLink'

interface Props extends ComponentPropsWithoutRef<'a'> {
  data?: Queries.DatoButtonFragment | null
  disableLightboxLinks?: boolean
}

export const DatoButton = ({
  data,
  disableLightboxLinks,
  ...props
}: Props): JSX.Element => {
  return (
    <DatoLink
      data={data?.button?.[0]}
      data-button
      styleVariant="BUTTON"
      disableLightboxLinks={disableLightboxLinks}
      showIcon
      {...props}
    />
  )
}

export const DatoButtonFragment = graphql`
  fragment DatoButton on DatoCmsDatoButton {
    __typename
    id: originalId
    button {
      ... on DatoCmsPageLink {
        ...PageLink
      }
      ... on DatoCmsExternalLink {
        ...ExternalLink
      }
      ... on DatoCmsTertiaryArticleLink {
        ...TertiaryArticleLink
      }
      ... on DatoCmsPdfLink {
        ...PdfLink
      }
      ... on DatoCmsFormLink {
        ...FormLink
      }
    }
  }
`
