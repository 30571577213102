import { css } from '@emotion/react'
import { graphql } from 'gatsby'
import { ComponentPropsWithoutRef } from 'react'

import { DatoLink } from '@/features/dato-link'
import { mq } from '@/theme/mixins'
import { colors } from '@/theme/variables'

interface Props extends ComponentPropsWithoutRef<'ul'> {
  data?: Queries.NavLinkGroupFragment | null
  isOpen: boolean
  onClickLink: () => void
}

export const NavLinkGroup = ({
  data,
  isOpen,
  onClickLink,
  ...props
}: Props): JSX.Element => {
  const { label, links } = data || {}
  const styles = {
    list: css`
      display: inline-block;
      list-style: none;
      margin: 0;
      padding: 0;
      &:before {
        content: attr(title);
        display: block;
        font-size: var(--fs-16);
        text-transform: uppercase;
        letter-spacing: 0.05em;
        font-weight: 450;
        color: ${colors.red};
        margin-bottom: 0.75em;
      }
      margin-bottom: 2em;
      li:last-of-type > & {
        margin-bottom: 0;
      }
    `,
    link: css`
      margin: 0.25em 0;
      font-size: var(--fs-18);
      font-weight: 375;
      line-height: 1.25;
      ${mq().m} {
        font-size: var(--fs-21);
      }
      @media (hover: hover) {
        &:hover {
          color: ${colors.red};
        }
      }
    `,
  }
  return (
    <ul
      css={styles.list}
      title={label || undefined}
      {...props}
    >
      {links?.map((link, i) => (
        <li
          key={i}
          title=""
        >
          <DatoLink
            data={link}
            styleVariant="LOWERCASE"
            css={styles.link}
            onClick={onClickLink}
            disableLightboxLinks
            tabIndex={isOpen ? 0 : -1}
          />
        </li>
      ))}
    </ul>
  )
}

export const NavLinkGroupFragment = graphql`
  fragment NavLinkGroup on DatoCmsNavLinkGroup {
    __typename
    id: originalId
    label
    links {
      ...PageLink
    }
  }
`
