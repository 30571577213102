import { css } from '@emotion/react'
import { graphql } from 'gatsby'
import { ComponentPropsWithoutRef } from 'react'

import {
  ArticleBody,
  ArticleContainer,
  ArticleHeading,
  ArticleLayoutOptions,
} from '@/features/article-sections'
import { colors } from '@/theme/variables'

interface Props extends ComponentPropsWithoutRef<'article'> {
  data?: Queries.AlumnaeArticleFragment | null
  layout: ArticleLayoutOptions
  disableLightboxLinks?: boolean
}

export const AlumnaeArticle = ({
  data,
  layout,
  disableLightboxLinks,
  ...props
}: Props): JSX.Element => {
  const styles = {
    subheading: css`
      font-size: var(--fs-48);
      font-family: var(--ff-display);
      text-transform: none;
      color: ${colors.gray45};
    `,
    alumnaeTitle: css`
      display: block;
      font-size: var(--fs-21);
      font-family: var(--ff-body);
      font-weight: 300;
      margin-top: 0.333em;
      letter-spacing: auto;
    `,
  }
  return (
    <ArticleContainer
      layout={layout}
      {...props}
    >
      <ArticleHeading
        heading={data?.heading}
        subheading={
          <span css={styles.subheading}>
            <span>{data?.alumna?.[0]?.name} </span>
            <span>’{data?.alumna?.[0]?.graduationYear?.slice(-2)}</span>
            {data?.alumna?.[0]?.title && (
              <span css={styles.alumnaeTitle}>
                {data?.alumna?.[0]?.title}
              </span>
            )}
          </span>
        }
      />
      <ArticleBody
        data={data?.blocks}
        disableLightboxLinks={disableLightboxLinks}
      />
    </ArticleContainer>
  )
}

export const AlumnaeArticleFragment = graphql`
  fragment AlumnaeArticle on DatoCmsAlumnaeArticle {
    id: originalId
    __typename
    heading
    blocks {
      ... on DatoCmsBodyTextBlock {
        ...BodyTextBlock
      }
      ... on DatoCmsMediaCarousel {
        ...MediaCarousel
      }
    }
    alumna {
      ...Alumna
    }
    category {
      name
    }
    meta {
      createdAt
    }
    slug
    seoMetaTags {
      tags
    }
  }
`
