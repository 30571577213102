import { css } from '@emotion/react'
import { Fragment } from 'react'

import {
  AlumnaeArticle,
  AthleticsTeamArticle,
  LandingTestimonialArticle,
  LeaderArticle,
  MissionArticle,
  NewsArticle,
  TertiaryArticle,
} from '@/features/article-templates'
import { BlackbaudForm, Form } from '@/features/forms'

export type LightboxContentData =
  | Queries.MissionArticleFragment
  | Queries.StoryArticleFragment
  | Queries.NewsArticleFragment
  | Queries.TertiaryArticleFragment
  | Queries.LeaderArticleFragment
  | Queries.AthleticsTeamArticleFragment
  | Queries.BlackbaudFormFragment
  | Queries.AlumnaeArticleFragment
  | Queries.FormFragment
  | Queries.LandingTestimonialArticleFragment

interface Props {
  data?: LightboxContentData | null
}
export const LightboxContent = ({ data }: Props): JSX.Element => {
  const style = css`
    background: #fff;
    grid-column: 2 / 3;
    grid-row: 2 / 3;
  `
  switch (data?.__typename) {
    case 'DatoCmsMissionArticle':
      return (
        <MissionArticle
          data={data}
          layout="LIGHTBOX"
          css={style}
        />
      )
    case 'DatoCmsTertiaryArticle':
      return (
        <TertiaryArticle
          data={data}
          layout="LIGHTBOX"
          disableLightboxLinks
          css={style}
        />
      )
    case 'DatoCmsAthleticsTeam':
      return (
        <AthleticsTeamArticle
          data={data}
          layout="LIGHTBOX"
          css={style}
        />
      )
    case 'DatoCmsLeader':
      return (
        <LeaderArticle
          data={data}
          layout="LIGHTBOX"
          css={style}
        />
      )
    case 'DatoCmsBlackbaudForm':
      return (
        <BlackbaudForm
          data={data}
          layout="LIGHTBOX"
          disableLightboxLinks
          css={style}
        />
      )
    case 'DatoCmsNewsArticle':
      return (
        <NewsArticle
          data={data}
          layout="LIGHTBOX"
          css={style}
        />
      )
    case 'DatoCmsAlumnaeArticle':
      return (
        <AlumnaeArticle
          data={data}
          layout="LIGHTBOX"
          css={style}
        />
      )
    case 'DatoCmsForm':
      return (
        <Form
          data={data}
          layout="LIGHTBOX"
          css={style}
        />
      )
    case 'DatoCmsLandingTestimonial':
      return (
        <LandingTestimonialArticle
          data={data}
          layout="LIGHTBOX"
          css={style}
        />
      )
    default:
      return <Fragment />
  }
}
