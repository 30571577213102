import { css } from '@emotion/react'
import { ComponentPropsWithoutRef } from 'react'

import { BodyTextBlock } from '@/components/BodyTextBlock'
import { MediaCarousel } from '@/features/carousel'
import { BodySection } from '@/features/page-sections'
import { firstChild } from '@/theme/mixins'

interface Props extends ComponentPropsWithoutRef<'div'> {
  data?:
    | readonly (
        | Queries.BodySectionFragment
        | Queries.BodyTextBlockFragment
        | Queries.MediaCarouselFragment
        | null
      )[]
    | null
  disableLightboxLinks?: boolean
}

export const ArticleBody = ({
  data,
  disableLightboxLinks,
  ...props
}: Props): JSX.Element => {
  const styles = {
    block: css`
      margin-left: var(--margin);
      margin-right: var(--margin);
      &:last-child {
        margin-bottom: 0;
      }
      &${firstChild} {
        margin-top: 0;
      }
    `,
    imageCarousel: css`
      max-width: var(--grid-w);
      margin: 3em 0 4em;
    `,
  }
  return (
    <div {...props}>
      {data?.map((block, i) => {
        switch (block?.__typename) {
          case 'DatoCmsBodySection':
            return (
              <BodySection
                data={block}
                disableLightboxLinks={disableLightboxLinks}
                layout="ARTICLE"
                key={i}
              />
            )
          case 'DatoCmsBodyTextBlock':
            return (
              <BodyTextBlock
                data={block}
                css={styles.block}
                disableLightboxLinks={disableLightboxLinks}
                key={i}
              />
            )
          case 'DatoCmsMediaCarousel':
            return (
              <MediaCarousel
                data={block}
                layout="ARTICLE"
                css={[styles.block, styles.imageCarousel]}
                key={i}
              />
            )
        }
      })}
    </div>
  )
}
