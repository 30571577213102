import { css } from '@emotion/react'
import { useEffect } from 'react'
import { useInView } from 'react-intersection-observer'

import { mq } from '@/theme/mixins'

import { useMissionScrollContext } from '../../contexts/missionScrollContext'
import { TextBlock } from './TextBlock'

interface Props {
  textBlock?: Queries.MissionTextFragment | null
}

export const Text3 = ({ textBlock }: Props): JSX.Element => {
  const { addViewZone, removeViewZone } = useMissionScrollContext()
  const { inView: inView8, ref: ref8 } = useInView()
  const { inView: inView9, ref: ref9 } = useInView()
  const { inView: inView10, ref: ref10 } = useInView()
  const { inView: inView11, ref: ref11 } = useInView()
  const { inView: inView12, ref: ref12 } = useInView()
  const { inView: inView13, ref: ref13 } = useInView()
  useEffect(() => {
    inView8 ? addViewZone(8) : removeViewZone(8)
    inView9 ? addViewZone(9) : removeViewZone(9)
    inView10 ? addViewZone(10) : removeViewZone(10)
    inView11 ? addViewZone(11) : removeViewZone(11)
    inView12 ? addViewZone(12) : removeViewZone(12)
    inView13 ? addViewZone(13) : removeViewZone(13)
  }, [
    inView8,
    inView9,
    inView10,
    inView11,
    inView12,
    inView13,
    addViewZone,
    removeViewZone,
  ])
  const styles = {
    section: css`
      box-sizing: border-box;
      display: grid;
      grid-template-columns: repeat(12, 1fr);
      grid-column-gap: var(--gtr);
      padding: 0 var(--margin);
      justify-content: center;
      align-items: flex-end;
      color: #fff;
    `,
    zone: css`
      grid-column: 1 / -1;
      height: max(5rem, 10vh);
    `,
    textBlock: css`
      grid-column: 1 / span 6;
      padding: 0 var(--margin) 90vh;
      max-width: 18ch;
      justify-self: center;
      ${mq().s} {
        grid-column: 1 / -1;
        max-width: 100%;
      }
      a {
        @media (hover: hover) {
          &:hover {
            color: #000;
          }
        }
      }
    `,
  }
  return (
    <section css={styles.section}>
      <div
        ref={ref8}
        css={[styles.zone, { height: `max(10rem, 20vh)` }]}
      />
      <div
        ref={ref9}
        css={styles.zone}
      />
      <div
        ref={ref10}
        css={styles.zone}
      />
      <div
        ref={ref11}
        css={styles.zone}
      />
      <div
        ref={ref12}
        css={[styles.zone, { height: `max(10rem, 20vh)` }]}
      />
      <TextBlock
        ref={ref13}
        textBlock={textBlock}
        css={styles.textBlock}
        decoColor={'#ffffff88'}
      />
    </section>
  )
}
