import { css } from '@emotion/react'
import { ComponentPropsWithoutRef, ReactNode, useRef } from 'react'

import { ArticleLayoutOptions } from '@/features/article-sections'
import { colors } from '@/theme/variables'

import { AcademicCalendarEvent } from './AcademicCalendarEvent'
import { AthleticCalendarEvent } from './AthleticCalendarEvent'
import { CalendarEvent } from './CalendarEvent'

type Events = readonly (
  | Queries.AthleticCalendarEventFragment
  | Queries.AdmissionsEventFragment
  | Queries.AlumnaeEventFragment
  | Queries.AcademicCalendarEventFragment
  | null
)[]

interface Props extends ComponentPropsWithoutRef<'ol'> {
  eventsByMonth?:
    | {
        month: string
        events?: Events | null
      }[]
    | null
  errorMessage: string | ReactNode
  layout?: ArticleLayoutOptions
}

// export const getEventsByMonth = ({ events }: { events: Events }) => {
//   return events.reduce(
//     (acc, event) => {
//       const date = new Date(event?.dateAndTime || '')
//       const month = format(date, 'MMMM')
//       const matchingMonth = acc.find(val => val.month === month)
//       if (matchingMonth) {
//         matchingMonth.events.push(event)
//       } else {
//         acc.push({ month: month, events: [event] })
//       }
//       return acc
//     },
//     [] as { month: string; events: Events }[]
//   )
// }

export const Calendar = ({
  eventsByMonth,
  errorMessage,
  layout,
  ...props
}: Props): JSX.Element => {
  const oddEvenIndex = useRef(0)
  const styles = {
    calendar: css`
      display: grid;
      list-style: none;
      margin: 0;
      padding: 0;
    `,
    monthGroup: css`
      margin: 0;
      display: grid;
    `,
    monthHeading: css`
      width: 100%;
      box-sizing: border-box;
      font-size: var(--fs-21);
      font-family: var(--ff-body);
      font-weight: 325;
      text-transform: uppercase;
      color: ${colors.gray30};
      background: #fff;
      margin: 1em 0 0;
      padding: 0.5em 0.25em;
      border-bottom: 2px solid ${colors.red};
      position: sticky;
      top: var(--nav-height-flex);
      z-index: 2;
      ${layout === 'LIGHTBOX' &&
      css`
        top: 0;
      `}
    `,
    eventList: css`
      list-style: none;
      margin: 0;
      padding: 0;
    `,
    error: css`
      font-size: var(--fs-21);
      font-family: var(--ff-body);
      font-weight: 350;
      margin: 1.5em 0 1em;
      color: ${colors.gray45};
      line-height: 1.333;
    `,
  }
  if (eventsByMonth?.length) {
    return (
      <ol
        css={styles.calendar}
        {...props}
      >
        {eventsByMonth.map((eventMonth, i) => {
          if (i === 0) {
            oddEvenIndex.current = 0
          }
          return (
            <li
              css={styles.monthGroup}
              key={i}
            >
              <h3 css={styles.monthHeading}>{eventMonth.month}</h3>
              <ol css={styles.eventList}>
                {eventMonth?.events?.map((event, ii) => {
                  const oddEven =
                    oddEvenIndex.current % 2 ? 'EVEN' : 'ODD'
                  oddEvenIndex.current += 1
                  switch (event?.__typename) {
                    case 'AthleticCalendarEvent':
                      return (
                        <AthleticCalendarEvent
                          data={event}
                          oddEven={oddEven}
                          key={ii}
                        />
                      )
                    case 'DatoCmsAdmissionsEvent':
                    case 'DatoCmsAlumnaeEvent':
                      return (
                        <CalendarEvent
                          data={event}
                          oddEven={oddEven}
                          key={ii}
                        />
                      )
                    case 'DatoCmsAcademicCalendarEvent':
                      return (
                        <AcademicCalendarEvent
                          data={event}
                          oddEven={oddEven}
                          key={ii}
                        />
                      )
                  }
                })}
              </ol>
            </li>
          )
        })}
      </ol>
    )
  } else return <h3 css={styles.error}>{errorMessage}</h3>
}
