import { css } from '@emotion/react'
import { useEffect } from 'react'
import { useInView } from 'react-intersection-observer'

import { absoluteFill, mq } from '@/theme/mixins'

import { useMissionScrollContext } from '../../contexts/missionScrollContext'
import { TextBlock } from './TextBlock'

interface Props {
  textBlock?: Queries.MissionTextFragment | null
}

export const Text4 = ({ textBlock }: Props): JSX.Element => {
  const { addViewZone, removeViewZone } = useMissionScrollContext()
  const { inView: inView14, ref: ref14 } = useInView()
  const { inView: inView15, ref: ref15 } = useInView()
  const { inView: inView16, ref: ref16 } = useInView()
  const { inView: inView17, ref: ref17 } = useInView()
  const { inView: inView18, ref: ref18 } = useInView({
    rootMargin: '0% 0% -45%',
  })
  useEffect(() => {
    inView14 ? addViewZone(14) : removeViewZone(14)
    inView15 ? addViewZone(15) : removeViewZone(15)
    inView16 ? addViewZone(16) : removeViewZone(16)
    inView17 ? addViewZone(17) : removeViewZone(17)
    inView18 ? addViewZone(18) : removeViewZone(18)
  }, [
    inView14,
    inView15,
    inView16,
    inView17,
    inView18,
    addViewZone,
    removeViewZone,
  ])
  const styles = {
    section: css`
      box-sizing: border-box;
      display: grid;
      grid-template-columns: repeat(12, 1fr);
      grid-column-gap: var(--gtr);
      padding: 0 var(--margin);
      justify-content: center;
      align-content: flex-end;
      color: #fff;
      position: relative;
    `,
    zone: css`
      grid-column: 1 / -1;
      grid-row: auto;
      height: 0;
      ${mq().s} {
        height: max(5rem, 10vh);
      }
    `,
    halfZone: css`
      grid-column: 1 / -1;
      grid-column: 1 / -1;
      ${absoluteFill}
      z-index: 0;
      grid-row: 4 / 5;
    `,
    textBlock: css`
      grid-column: 1 / -1;
      grid-row: 4 / 5;
      padding: 10vh var(--margin) 80vh;
      text-align: center;
      justify-self: center;
      max-width: 30ch;
      position: relative;
      z-index: 1;
      ${mq().s} {
        padding-top: 0;
        grid-column: 1 / -1;
        text-align: left;
        max-width: 100%;
      }
      a {
        @media (hover: hover) {
          &:hover {
            color: #000;
          }
        }
      }
    `,
  }
  return (
    <section css={styles.section}>
      <div
        ref={ref14}
        css={styles.zone}
        data-14
      />
      <div
        ref={ref15}
        css={styles.zone}
        data-15
      />
      <div
        ref={ref16}
        css={styles.zone}
        data-16
      />
      <TextBlock
        ref={ref17}
        textBlock={textBlock}
        css={styles.textBlock}
        decoColor={'#ffffff88'}
        data-17
      />
      <div
        ref={ref18}
        css={[styles.halfZone]}
        data-18
      />
    </section>
  )
}
