import { css } from '@emotion/react'
import { graphql } from 'gatsby'
import { ComponentPropsWithoutRef } from 'react'

import { GatsbyImageFocused } from '@/components/GatsbyImageFocused'
import { Carousel } from '@/features/carousel'
import { useWindowWidth } from '@/hooks/useWindowDimensions'
import { mq } from '@/theme/mixins'
import { breakpoints } from '@/theme/variables'

interface Props extends ComponentPropsWithoutRef<'section'> {
  data?: Queries.ArticleHeroCarouselFragment | null
}

export const ArticleHeroCarousel = ({
  data,
  ...props
}: Props): JSX.Element => {
  const windowWidth = useWindowWidth()

  const styles = {
    section: css`
      display: grid;
      --margin: 1rem;
      margin: var(--margin) 0;
    `,
    slider: css`
      grid-column: 1 / -1;
      --gtr: var(--margin);
      --slide-width: calc(var(--grid-w) - 2 * var(--margin));
      --scroll-width: calc(var(--slide-width) + var(--gtr));
      transition: height 750ms ease;
      ${mq().s} {
        --margin: 0.5rem;
      }
    `,
    sliderContent: css`
      display: grid;
      grid-column-gap: var(--gtr);
      grid-template-columns: repeat(
        ${data?.images?.length},
        var(--slide-width)
      );
      padding: 0 var(--margin);
    `,
    scrollArea: css`
      scroll-padding: 0 var(--margin);
    `,
    nav: css`
      left: calc(var(--margin) * 2);
      bottom: var(--margin);
    `,
    slide: css`
      width: 100%;
      max-height: max(75svh, 40vw);
    `,
  }
  return (
    <section
      css={styles.section}
      {...props}
    >
      <Carousel
        css={styles.slider}
        contentCss={styles.sliderContent}
        navCss={styles.nav}
        scrollAreaCss={styles.scrollArea}
        navVariant="CORNER"
        snap
        {...props}
      >
        {windowWidth &&
          data?.images?.map((image, i) => (
            <GatsbyImageFocused
              image={
                windowWidth > breakpoints.ms
                  ? image.desktopImage
                  : image.mobileImage
              }
              data={image}
              key={i}
              css={styles.slide}
            />
          ))}
      </Carousel>
    </section>
  )
}

export const ArticleHeroCarouselFragment = graphql`
  fragment ArticleHeroCarousel on DatoCmsImageCarousel {
    __typename
    id: originalId
    images {
      desktopImage: gatsbyImageData(
        width: 1440
        imgixParams: {
          q: 75
          ar: "2:1"
          fit: "crop"
          crop: "focalpoint"
        }
      )
      mobileImage: gatsbyImageData(
        width: 720
        imgixParams: {
          q: 75
          ar: "4:3"
          fit: "crop"
          crop: "focalpoint"
        }
      )
      ...ImageFocalData
    }
  }
`
