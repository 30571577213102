import {
  ComponentPropsWithoutRef,
  Fragment,
  type MouseEventHandler,
  useState,
} from 'react'

import { Lightbox, LightboxContentData } from '../Lightbox'

interface Props extends ComponentPropsWithoutRef<'a'> {
  data?: LightboxContentData | null
}

export const LightboxLink = ({
  data,
  children,
  onClick,
  ...props
}: Props): JSX.Element => {
  const [isOpen, setIsOpen] = useState(false)
  const [entry, setEntry] = useState<{
    title: string
    path: string
  } | null>(null)

  const handleOpen: MouseEventHandler<HTMLAnchorElement> = e => {
    e.preventDefault()
    onClick && onClick(e)
    setEntry({
      title: document.title,
      path: window.location.pathname,
    })
    setTimeout(() => {
      setIsOpen(true)
    }, 10)
  }
  const slug = `/${data?.slug}/`.replace(/\/{2,}/g, '/')
  return (
    <Fragment>
      <a
        href={slug}
        onClick={handleOpen}
        {...props}
      >
        {children}
      </a>
      <Lightbox
        data={data}
        isOpen={isOpen}
        onClose={() => setIsOpen(false)}
        entry={entry}
        slug={slug}
      />
    </Fragment>
  )
}
