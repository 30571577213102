import { css } from '@emotion/react'
import { graphql, useStaticQuery } from 'gatsby'
import { useEffect } from 'react'

import { useMissionScrollContext } from '../../contexts/missionScrollContext'
import { Text1 } from './Text__1'
import { Text2 } from './Text__2'
import { Text3 } from './Text__3'
import { Text4 } from './Text__4'
import { Text5 } from './Text__5'
import { TextIntro } from './Text__Intro'
import { TextOutro } from './Text__Outro'

export const TextContainer = (): JSX.Element => {
  const { datoCmsMissionPage } =
    useStaticQuery<Queries.MissionTextContainerQuery>(graphql`
      query MissionTextContainer {
        datoCmsMissionPage {
          pageHeading
          textBlocks {
            ...MissionText
          }
        }
      }
    `)
  const { textBlocks, pageHeading } = datoCmsMissionPage || {}
  const { clearViewZones } = useMissionScrollContext()
  useEffect(() => {
    return () => clearViewZones()
  }, [clearViewZones])
  const styles = {
    container: css`
      --gtr-m: 1rem;
      --margin: 1.5rem;
      width: 100%;
      position: relative;
      display: grid;
    `,
  }
  return (
    <div css={styles.container}>
      <TextIntro pageHeading={pageHeading} />
      <Text1 textBlock={textBlocks?.[0]} />
      <Text2 textBlock={textBlocks?.[1]} />
      <Text3 textBlock={textBlocks?.[2]} />
      <Text4 textBlock={textBlocks?.[3]} />
      <Text5 textBlock={textBlocks?.[4]} />
      <TextOutro />
    </div>
  )
}
