import { css } from '@emotion/react'
import { graphql } from 'gatsby'
import { ComponentPropsWithoutRef } from 'react'

import { mq } from '@/theme/mixins'
import { colors } from '@/theme/variables'

import { BoardPerson } from './BoardPerson'

interface Props extends ComponentPropsWithoutRef<'ul'> {
  data?: Queries.BoardGroupFragment | null
}

export const BoardGroup = ({ data, ...props }: Props): JSX.Element => {
  const styles = {
    container: css`
      list-style: none;
      padding: 0;
      margin: 3em 0;
      columns: 3;
      gap: 2em;
      ${mq().s} {
        columns: 2;
      }
    `,
    heading: css`
      font-family: var(--ff-body);
      text-transform: uppercase;
      color: ${colors.gray85};
      font-size: var(--fs-18);
      font-weight: 300;
      margin: 0 0 1em;
      column-span: all;
      &:after {
        content: '';
        display: block;
        width: 2em;
        margin-top: 1em;
        height: 2px;
        background: ${colors.redLight};
      }
    `,
    listItem: css`
      display: inline-block;
      width: 100%;
    `,
  }
  return (
    <ul
      css={styles.container}
      {...props}
    >
      <h3 css={styles.heading}>{data?.title}</h3>
      {data?.people?.map((person, i) => (
        <li
          css={styles.listItem}
          key={i}
        >
          <BoardPerson data={person} />
        </li>
      ))}
    </ul>
  )
}

export const BoardGroupFragment = graphql`
  fragment BoardGroup on DatoCmsBoardGroup {
    __typename
    id: originalId
    title
    people {
      ...BoardPerson
    }
  }
`
