import { css } from '@emotion/react'
import { graphql } from 'gatsby'
import { ComponentPropsWithoutRef } from 'react'

import { mq } from '@/theme/mixins'
import { colors } from '@/theme/variables'

import { Alumna } from './Alumna'

interface Props extends ComponentPropsWithoutRef<'ul'> {
  data?: Queries.AlumnaeGroupFragment | null
}

export const AlumnaeGroup = ({
  data,
  ...props
}: Props): JSX.Element => {
  const styles = {
    list: css`
      list-style: none;
      margin: 0 0 2em;
      padding: 0;
      display: inline-block;
      ${mq().s} {
        columns: 2;
        gap: 3em;
        width: 100%;
      }
    `,
    heading: css`
      font-family: var(--ff-body);
      text-transform: uppercase;
      color: rgba(0, 0, 0, 0.55);
      font-size: var(--fs-16);
      font-weight: 350;
      margin: 0 0 1.25em;
      column-span: all;
      &:after {
        content: '';
        display: block;
        width: 2em;
        margin-top: 1em;
        height: 2px;
        background: ${colors.red};
      }
    `,
  }
  return (
    <ul
      css={styles.list}
      {...props}
    >
      <h3 css={styles.heading}>{data?.heading}</h3>
      {data?.alumnae?.map((block, i) => (
        <li key={i}>
          <Alumna
            data={block}
            headingLevel={4}
          />
        </li>
      ))}
    </ul>
  )
}

export const AlumnaeGroupFragment = graphql`
  fragment AlumnaeGroup on DatoCmsAlumnaeGroup {
    __typename
    id: originalId
    heading
    alumnae {
      ...Alumna
    }
  }
`
