export const toDate = (dateString: string, locale = 'en-US') => {
  const date = new Date(`${dateString}T00:00:00`)
  return date.toLocaleString(locale, {
    month: 'long',
    day: 'numeric',
    year: 'numeric',
  })
}

export const toSlug = (string?: string | null) =>
  string
    ?.replace(/[\s/]+/g, '-')
    .replace(/[^\w\d-]+/g, '')
    .replace(/--+/g, '-')
    .toLowerCase()

export const getLocalePrefix = (locale: string | null) => {
  if (locale === 'en' || locale === null) {
    return '/'
  } else {
    return `/${locale.slice(0, 2)}/`
  }
}
