import { css } from '@emotion/react'
import { graphql } from 'gatsby'
import { ComponentPropsWithoutRef } from 'react'

import { colors } from '@/theme/variables'

import { AccordionItem, AccordionItemData } from './AccordionItem'

interface Props extends ComponentPropsWithoutRef<'ul'> {
  data?:
    | Queries.AccordionFragment
    | { items?: AccordionItemData[] }
    | null
}

export const Accordion = ({ data, ...props }: Props): JSX.Element => {
  const styles = {
    list: css`
      list-style: none;
      padding: 0;
      margin: 0;
      --text-color: ${colors.gray20};
      --button-color: ${colors.gray45};
      --hover-color: ${colors.red};
    `,
  }
  return (
    <ul
      css={styles.list}
      {...props}
    >
      {data?.items?.map((item, i) => {
        return (
          <AccordionItem
            data={item}
            key={i}
          />
        )
      })}
    </ul>
  )
}

export const AccordionFragment = graphql`
  fragment Accordion on DatoCmsAccordion {
    __typename
    id: originalId
    items {
      ...AccordionItem
    }
  }
`
