import { css } from '@emotion/react'
import { graphql } from 'gatsby'
import { ComponentPropsWithoutRef } from 'react'

import { DatoLink } from '@/features/dato-link'
import { colors } from '@/theme/variables'

interface Props extends ComponentPropsWithoutRef<'section'> {
  data?: Queries.CTABarFragment | null
}

export const CTABar = ({ data, ...props }: Props): JSX.Element => {
  const styles = {
    section: css`
      background: ${colors.red};
      padding: var(--row-36) var(--margin);
      color: #fff;
      text-align: center;
      display: flex;
      align-items: center;
      justify-content: center;
      flex-wrap: wrap;
      font-size: var(--fs-18);
      grid-row-gap: 1em;
    `,
    leadIn: css`
      display: inline-block;
      font-size: var(--fs-48);
      margin: 0;
    `,
    links: css`
      display: inline-flex;
      gap: 1em;
      flex-wrap: wrap;
      justify-content: center;
    `,
    link: css`
      margin: 0;
      --button-hover-bg-color: #fff;
      --button-hover-color: ${colors.red};
    `,
  }
  return (
    <section
      css={styles.section}
      {...props}
    >
      <h2 css={styles.leadIn}>{data?.leadIn}&ensp;</h2>
      <div css={styles.links}>
        {data?.links?.map((link, i) => (
          <DatoLink
            data={link}
            key={i}
            styleVariant="BUTTON"
            showIcon
            css={styles.link}
          />
        ))}
      </div>
    </section>
  )
}

export const CTABarFragment = graphql`
  fragment CTABar on DatoCmsCtaBar {
    __typename
    id: originalId
    leadIn
    links {
      ... on DatoCmsPageLink {
        ...PageLink
      }
      ... on DatoCmsExternalLink {
        ...ExternalLink
      }
      ... on DatoCmsTertiaryArticleLink {
        ...TertiaryArticleLink
      }
      ... on DatoCmsPdfLink {
        ...PdfLink
      }
      ... on DatoCmsFormLink {
        ...FormLink
      }
    }
  }
`
