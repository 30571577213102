import { css } from '@emotion/react'
import { ComponentPropsWithoutRef, useState } from 'react'

import { mq } from '@/theme/mixins'
import { colors } from '@/theme/variables'

import { DirectoryNav } from './DirectoryNav'
import { DirectorySection } from './DirectorySection'

interface Props extends ComponentPropsWithoutRef<'section'> {
  data?: readonly (Queries.DirectorySectionFragment | null)[] | null
}

export const Directory = ({ data, ...props }: Props): JSX.Element => {
  const [activeSectionId, setActiveSectionId] = useState<string>()
  const styles = {
    directory: css`
      background-color: #fff;
      display: grid;
      grid-template-columns: auto 1fr;
      ${mq().s} {
        grid-template-columns: 1fr;
        padding-top: var(--row-36);
        padding-bottom: var(--row-36);
      }
    `,
    directoryList: css`
      background-color: #fff;
      list-style: none;
      padding: 0;
      margin: var(--row-72) var(--margin) var(--row-108) 0;
      padding-bottom: var(--row-54);
      display: grid;
      row-gap: var(--row-108);
      padding-left: var(--margin);
      border-left: 2px solid ${colors.gray85};
      ${mq().s} {
        margin-left: var(--margin);
        border: none;
      }
    `,
  }
  return (
    <section
      css={styles.directory}
      {...props}
    >
      <DirectoryNav
        data={data}
        activeSectionId={activeSectionId}
      />
      <ul css={styles.directoryList}>
        {data?.map((section, i) => (
          <li key={i}>
            <DirectorySection
              data={section}
              setActiveSectionId={setActiveSectionId}
            />
          </li>
        ))}
      </ul>
    </section>
  )
}
