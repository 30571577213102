export const colors = {
  redLight: '#eb3342',
  // redLight: '#ff4d4d',
  red: '#d92636',
  redMid: '#b41422',
  redDark: '#82000c',
  gray98: '#fafafa',
  gray95: '#f4f4f4',
  gray90: '#e6e6e6',
  gray85: '#d9d9d9',
  gray75: '#bfbfbf',
  gray65: '#a5a5a5',
  gray45: '#777777',
  gray30: '#4d4d4d',
  gray20: '#303030',
  gray10: '#1b1b1b',
}

export const breakpoints = {
  l: 1440,
  ls: 1260,
  ml: 1080,
  m: 900,
  ms: 720,
  s: 540,
}
