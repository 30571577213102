import { css } from '@emotion/react'
import { graphql } from 'gatsby'
import { ComponentPropsWithoutRef } from 'react'

import { mq } from '@/theme/mixins'
import { colors } from '@/theme/variables'

import { bodyGrid, bodyHeading } from '../../styles'
import { DescriptiveLink } from './DescriptiveLink'

interface Props extends ComponentPropsWithoutRef<'section'> {
  data?: Queries.DescriptiveLinksSectionFragment | null
  bgColor: 'WHITE' | 'GRAY'
}

export const DescriptiveLinksSection = ({
  data,
  bgColor,
  ...props
}: Props): JSX.Element => {
  const styles = {
    section: css`
      ${bodyGrid}
      background: ${bgColor === 'WHITE'
        ? '#fff'
        : bgColor === 'GRAY' && colors.gray95};
    `,
    heading: css`
      ${bodyHeading}
    `,
    links: css`
      display: grid;
      grid-template-columns: 1fr 1fr;
      grid-column-gap: var(--margin);
      grid-row-gap: 1.5em;
      ${mq().ms} {
        margin-top: 1em;
      }
      ${mq().s} {
        grid-template-columns: 1fr;
      }
    `,
  }
  return (
    <section
      css={styles.section}
      {...props}
    >
      <h2 css={styles.heading}>{data?.heading}</h2>
      <div css={styles.links}>
        {data?.descriptiveLinks?.map((link, i) => (
          <DescriptiveLink
            data={link}
            key={i}
          />
        ))}
      </div>
    </section>
  )
}

export const DescriptiveLinksSectionFragment = graphql`
  fragment DescriptiveLinksSection on DatoCmsDescriptiveLinksSection {
    __typename
    id: originalId
    heading
    descriptiveLinks: links {
      ...DescriptiveLink
    }
  }
`
