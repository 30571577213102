import { Link, graphql } from 'gatsby'
import { ComponentPropsWithoutRef, Fragment, ReactNode } from 'react'

import { LightboxLink } from '@/features/lightbox'

import { useBlackbaudFormQuery } from '../../hooks/useBlackbaudFormQuery'
import { DatoLinkIcon } from './DatoLinkIcon'

interface Props extends ComponentPropsWithoutRef<'a'> {
  data?:
    | Queries.FormLinkFragment
    | {
        linkText: string | ReactNode
        form: {
          __typename: NonNullable<
            Queries.FormLinkFragment['form']
          >['__typename']
          slug: string
        }
      }
    | null
  showIcon?: boolean
  disableLightboxLinks?: boolean
}

export const FormLink = ({
  data,
  showIcon,
  disableLightboxLinks,
  ...props
}: Props): JSX.Element => {
  const blackbaudForm = useBlackbaudFormQuery(data?.form?.slug)
  const getForm = () => {
    switch (data?.form?.__typename) {
      case 'DatoCmsBlackbaudForm': {
        return blackbaudForm
      }
      case 'DatoCmsForm': {
        return data.form as Queries.FormFragment
      }
    }
  }
  const form = getForm()
  if (form) {
    if (disableLightboxLinks) {
      return (
        <Link
          to={`/${form.slug}/`}
          {...props}
        >
          <span>{data?.linkText}</span>
          {showIcon && <DatoLinkIcon iconType="LIGHTBOX" />}
        </Link>
      )
    } else {
      return (
        <LightboxLink
          data={form}
          {...props}
        >
          <span>{data?.linkText}</span>
          {showIcon && <DatoLinkIcon iconType="LIGHTBOX" />}
        </LightboxLink>
      )
    }
  } else return <Fragment />
}

export const FormLinkFragment = graphql`
  fragment FormLink on DatoCmsFormLink {
    __typename
    id: originalId
    linkText
    form {
      __typename
      ... on DatoCmsBlackbaudForm {
        slug
      }
      ... on DatoCmsForm {
        ...Form
      }
    }
  }
`
