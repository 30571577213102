import { css } from '@emotion/react'
import { ComponentPropsWithoutRef, useEffect } from 'react'
import { useInView } from 'react-intersection-observer'

import { Deco } from '@/components/Deco'
import { ScrollHintArrow } from '@/components/ScrollHintArrow'
import { mq } from '@/theme/mixins'
import { colors } from '@/theme/variables'

import { useMissionScrollContext } from '../../contexts/missionScrollContext'
import { BrearleyLogoMission } from './BrearleyLogoMission'

interface Props extends ComponentPropsWithoutRef<'section'> {
  pageHeading?: string | null
}

export const TextIntro = ({ pageHeading }: Props): JSX.Element => {
  const { addViewZone, removeViewZone } = useMissionScrollContext()
  const { inView: inView0, ref: ref0 } = useInView({
    initialInView: true,
  })
  useEffect(() => {
    if (inView0) {
      addViewZone(0)
    } else removeViewZone(0)
  }, [inView0, addViewZone, removeViewZone])
  const styles = {
    section: css`
      min-height: calc(100vh - 2 * var(--nav-height) - var(--margin));
      padding: 0 var(--margin) calc(25vh + var(--nav-height));
      display: flex;
      justify-content: center;
      align-items: center;
      position: relative;
      border-top: var(--margin) solid #000;
    `,
    textContainer: css`
      border: 4px solid ${colors.red};
      padding: 4px;
      margin: var(--margin);
      > div {
        background: ${colors.red};
        padding: 3rem 3rem;
        display: grid;
        justify-items: center;
        ${mq().ms} {
          padding: 3rem 2rem;
        }
      }
      h1 {
        font-size: var(--fs-48);
        margin: 1em 0 0;
      }
      h2 {
        font-size: var(--fs-36);
        text-transform: uppercase;
        color: #fff;
        letter-spacing: 0.1em;
        margin: 0.75em 0 1em;
        line-height: 1;
        text-align: center;
        ${mq().ms} {
          margin-top: 1em;
        }
      }
    `,
    scrollHintArrow: css`
      position: fixed;
      left: 50%;
      bottom: calc(var(--margin) + 1em);
      transform: translateX(-50%);
    `,
    deco: css`
      font-size: 6rem;
    `,
  }
  return (
    <section
      css={styles.section}
      ref={ref0}
    >
      <div css={styles.textContainer}>
        <div>
          <Deco
            flip
            css={styles.deco}
          />
          <h1>
            <BrearleyLogoMission fill={'#fff'} />
          </h1>
          <h2>{pageHeading}</h2>
          <Deco css={styles.deco} />
        </div>
      </div>
      <ScrollHintArrow css={styles.scrollHintArrow} />
    </section>
  )
}
