import { css } from '@emotion/react'
import { ComponentPropsWithoutRef } from 'react'

interface Props extends ComponentPropsWithoutRef<'svg'> {
  fill?: string
}

export const BrearleyLogoShort = ({
  fill,
  ...props
}: Props): JSX.Element => {
  const style = css`
    height: 1em;
    width: auto;
    path {
      fill: currentColor;
      fill: ${fill};
    }
  `
  return (
    <svg
      viewBox="0 0 258 36"
      css={style}
      aria-label="Brearley"
      {...props}
    >
      <path d="M26.6026 26.8884C26.7266 33.7444 19.4134 34.6252 14.9275 34.655C8.67079 34.7026 8.67079 34.7919 8.67079 28.3227V17.4435C10.0107 17.2769 12.9206 17.2174 13.1862 17.2174C22.4296 17.2174 26.4787 20.0264 26.6026 26.8825M10.9078 16.0926C10.17 16.0926 9.33778 16.0926 8.67079 16.1521V12.7836C8.67079 11.0398 8.56455 2.30914 9.30236 1.80327C9.70373 1.41048 12.112 1.35096 12.8498 1.35096C17.4714 1.35096 22.4945 4.01719 22.4945 9.07588C22.4945 15.8783 14.7917 16.0271 10.9078 16.0866M18.4749 16.3663V16.2533C24.3656 14.9618 26.7797 12.0337 26.7797 8.49264C26.7797 3.03521 21.4202 0 15.199 0C14.5969 0 12.655 0.113075 10.778 0.226151C8.83606 0.339228 6.95906 0.452307 6.22715 0.452307C5.35358 0.452307 4.2203 0.398742 3.14604 0.398742C2.07178 0.34518 1.00343 0.285667 0.0649277 0.232104V1.2974C1.67041 1.52356 3.14604 1.74971 3.88385 2.92809C4.75152 4.21954 5.02304 8.43908 5.02304 11.5874V14.2893C5.02304 15.188 4.95811 29.8701 4.2203 32.7922C3.81893 34.5895 1.94193 34.8157 0 34.9883V36C3.34673 35.8334 4.35015 35.7738 6.42784 35.7738C7.02989 35.7738 8.83606 35.8334 10.5773 35.8334C12.3835 35.8869 14.1955 35.9464 14.8625 35.9464C17.8728 35.9464 31 35.9464 31 26.4421C31 22.6153 28.456 17.9494 18.4808 16.3723" />
      <path d="M89.0106 12.9607H88.286C87.2846 16.4431 87.0077 16.4431 84.999 16.6689C83.3849 16.8353 79.933 16.8947 77.0936 16.8947C75.3146 16.8947 73.7536 16.8947 73.088 16.8412C73.029 15.8845 73.029 12.0693 73.029 9.71014C73.029 6.45362 73.3059 4.09442 73.5298 2.07395C75.7034 1.79465 78.1481 1.51535 81.8769 1.51535C84.4394 1.51535 89.9472 1.56883 89.9472 4.60548C89.9472 4.88478 89.7233 6.34665 89.7233 6.90525L90.4479 7.13107C91.1135 6.17431 91.5023 3.87455 91.7851 0C88.8338 0.112909 84.8282 0.22582 82.9313 0.22582H65.5066L65.4477 0.956752C68.7347 1.62826 68.9527 1.62826 69.1235 9.15748L69.2884 17.8633C69.3415 19.7174 69.0646 29.2611 68.6758 32.0126C68.34 34.3123 68.2281 34.3717 65.0589 35.0432L65 36C68.505 35.8336 74.4664 35.6078 79.6384 35.6078C79.6914 35.6078 82.2009 35.7207 84.9283 35.7207C87.6557 35.7207 90.66 35.7742 91.7203 35.8871C92.8925 33.415 93.3343 31.7867 94 27.5735H93.1635C91.4375 32.5177 89.0989 34.2588 80.5279 34.2588C75.8507 34.2588 74.5135 33.6943 73.4591 33.415C72.9053 30.2714 72.9053 24.5962 72.9584 22.4094L73.0703 18.5348C74.1247 18.3684 75.244 18.2555 77.9714 18.2555C86.8781 18.2555 87.8795 18.4813 88.0445 23.3126H88.9929V12.9785L89.0106 12.9607Z" />
      <path d="M115.863 21.5325C114.962 21.5858 112.253 21.645 111.746 21.645L109.21 21.7041C109.15 21.7041 106.501 21.7041 104.418 21.3669C104.645 20.9172 104.699 20.7515 105.266 19.2426L108.369 11.3018C108.649 10.6864 109.443 8.67455 110.057 7.27218C110.511 8.22485 112.313 12.2485 112.653 13.2012L114.628 18.2308C114.908 18.7929 115.642 20.9172 115.869 21.5325M127.994 35.7929V34.8402C126.866 34.7278 125.685 34.503 125.064 33.6095C123.769 31.8757 121.454 25.7278 119.706 21.1953L113.9 5.81656C113.339 4.24852 112.886 3.07692 112.038 0H111.137C110.009 4.02367 108.148 8.21893 106.513 11.9645L102.115 22.4201C101.268 24.716 97.8845 32.6509 96.536 33.7692C96.4226 33.8817 95.5753 34.6627 94 35.0533V35.8343C95.3545 35.7219 97.0432 35.6095 99.0183 35.6095C101.727 35.6095 103.135 35.8343 104.49 36V35.1065C100.6 34.5444 100.092 33.5976 100.092 32.4793C100.092 31.7515 100.373 31.0237 101.447 28.5089L103.476 23.4793C105.057 23.2012 107.533 23.0888 109.228 23.142H111.424C113.005 23.142 115.034 23.142 116.609 23.3669L118.638 28.8461C118.865 29.3491 119.879 32.0888 119.879 33.2071C119.879 34.4379 118.298 34.7751 117.116 34.9941V35.8876C118.017 35.8284 120.332 35.6095 123.656 35.6095C125.965 35.6095 126.872 35.6686 128 35.7751" />
      <path d="M168.982 24.6839C168.93 33.6281 168.818 34.0663 165 34.8433L165.053 35.6728H184.034C184.754 35.6728 186.359 35.7254 187.243 35.7254C188.127 35.7254 189.07 35.778 190.95 36C192.168 33.0205 192.83 29.8191 193 28.6565L192.116 28.5455C191.507 30.8648 190.347 31.747 189.738 32.1325C188.52 32.7927 185.258 34.0663 179.442 34.0663C174.072 34.0663 173.796 33.7917 173.351 33.4061C172.795 32.8511 172.631 31.3088 172.631 27.0558C172.631 22.8027 172.742 17.1768 172.854 12.7601C172.965 8.2851 173.018 4.64547 173.41 3.70489C174.019 2.26774 175.788 2.21516 177.504 2.05158L177.556 1C176.116 1.111 173.96 1.111 171.577 1.111C171.021 1.111 170.47 1.05842 165.381 1V1.94058C169.088 2.05158 169.088 2.77016 169.035 12.8186L168.982 24.6897V24.6839Z" />
      <path d="M216.993 12.9667H216.268C215.267 16.449 214.99 16.449 212.981 16.6748C211.367 16.8412 207.915 16.9006 205.076 16.9006C203.297 16.9006 201.736 16.9006 201.07 16.8471C201.017 15.8904 201.017 12.0753 201.017 9.71608C201.017 6.45956 201.294 4.10036 201.518 2.07989C203.692 1.80059 206.136 1.51535 209.865 1.51535C212.428 1.51535 217.935 1.57478 217.935 4.60548C217.935 4.88478 217.712 6.34665 217.712 6.90525L218.436 7.13107C219.102 6.17431 219.496 3.87455 219.773 0C216.822 0.112909 212.816 0.225815 210.92 0.225815H193.495L193.442 0.956752C196.729 1.62826 196.947 1.62826 197.118 9.15748L197.283 17.8633C197.336 19.7174 197.059 29.2611 196.67 32.0125C196.334 34.3123 196.222 34.3717 193.053 35.0432L193 36C196.505 35.8336 202.466 35.6078 207.638 35.6078C207.697 35.6078 210.201 35.7207 212.928 35.7207C215.656 35.7207 218.66 35.7742 219.72 35.8871C220.887 33.415 221.334 31.7867 222 27.5735H221.164C219.438 32.5177 217.099 34.2588 208.528 34.2588C203.851 34.2588 202.514 33.6943 201.459 33.415C200.899 30.2714 200.899 24.5962 200.958 22.4094L201.07 18.5348C202.131 18.3684 203.244 18.2555 205.971 18.2555C214.878 18.2555 215.88 18.4813 216.05 23.3067H216.999V12.9726L216.993 12.9667Z" />
      <path d="M244.931 14.2172C243.256 16.7623 243.038 17.0373 242.552 17.6984C241.255 16.1479 232.393 4.42862 232.393 3.10047C232.393 2.26964 233.582 2.21698 235.583 2.04731L235.692 1H234.451C232.668 1 230.992 1.11117 229.483 1.11117C228.889 1.11117 226.133 1 225.596 1H223L223.16 1.88348C226.402 2.04731 226.619 2.32815 231.913 9.06837C233.154 10.6189 235.423 13.714 237.316 16.4756L239.477 19.7404C239.528 20.3489 239.528 21.6185 239.528 23.0578C239.528 33.2852 239.368 33.4549 238.665 34.116C237.853 34.9995 236.235 35.1692 234.989 35.2218V35.9941C235.743 35.9941 236.881 35.883 238.23 35.8303C239.094 35.8303 240.443 35.5553 241.42 35.5553C243.421 35.5553 245.794 35.8888 247.904 36L247.955 35.2803C247.36 35.1165 246.12 34.783 245.148 34.5607C243.313 34.1745 243.313 33.566 243.256 29.3066L243.147 19.4654C245.039 16.5341 246.926 13.5502 248.818 10.5662C253.678 3.04781 254.707 2.38081 257.949 2.21699L258 1.11117C257.188 1.11117 253.569 1.22234 252.815 1.22234C252.329 1.22234 249.899 1.11117 249.356 1.11117L247.143 1L247.035 2.04731C248.115 1.99465 250.602 1.93614 250.602 3.81428C250.602 5.30625 248.064 9.39602 246.926 11.1103L244.925 14.2055L244.931 14.2172Z" />
      <path d="M43.1412 17.3157C40.591 17.3157 39.4303 17.0396 38.7619 16.8693C38.7619 12.094 38.7619 3.77093 39.3716 2.43759C39.7586 1.43906 42.6429 1.163 43.8037 1.163C50.0648 1.163 52.6092 6.76652 52.6092 9.93245C52.6092 17.3157 44.9645 17.3157 43.1354 17.3157M64 35.5007C64 35.1131 63.8359 35.0015 63.5017 34.9427C58.3486 34.5786 53.4944 27.7004 48.7047 20.4758C48.6168 20.3407 48.5758 20.2349 48.482 20.094L47.585 18.743L49.1796 18.2614C54.4441 16.3172 56.7129 12.7107 56.7129 9.26872C56.7129 4.71659 52.5564 0 44.6889 0C43.1354 0 36.2118 0.663728 34.8282 0.663728C33.8316 0.663728 32.6122 0.66373 31.17 0.610867C31.1114 0.886932 31.1114 1.05727 31.0586 1.4978C34.7696 1.99707 34.9924 2.0558 34.9337 12.7636V24.7518C34.881 33.4097 34.2713 33.9677 32.108 34.5786C31.1114 34.8546 31 34.9134 31 35.301C31 35.5771 31.2228 35.6887 31.5511 35.6887C31.8266 35.6887 32.6591 35.5771 33.7671 35.5242C34.8223 35.4126 36.1473 35.3598 37.4253 35.3598C37.8708 35.3598 39.0844 35.4714 40.251 35.583C41.359 35.6946 42.3029 35.8062 42.5198 35.8062C42.854 35.8062 42.9067 35.4714 42.9067 35.2482C42.9067 35.025 42.7953 35.025 42.297 34.8605C39.8582 34.1968 39.6355 34.0852 39.1958 33.1395C38.5861 31.6946 38.5333 26.7548 38.5333 24.9222L38.5919 18.4258C39.8231 18.5962 41.0366 18.5903 42.256 18.6314C42.1798 18.7019 42.2208 18.7606 42.6019 18.7606L45.7031 23.2012C45.7618 23.2893 45.8145 23.3363 45.8732 23.4185C50.3873 29.7797 55.6401 36 62.6106 36C63.9941 36 63.9941 35.7768 63.9941 35.5007" />
      <path d="M142.13 17.3129C139.579 17.3129 138.419 17.0369 137.756 16.8666C137.756 12.092 137.756 3.77031 138.366 2.43719C138.753 1.43883 141.637 1.1628 142.798 1.1628C149.059 1.1628 151.609 6.76541 151.609 9.93083C151.609 17.3129 143.964 17.3129 142.135 17.3129M163 35.4949C163 35.1073 162.836 34.9958 162.502 34.937C157.349 34.5729 152.494 27.6959 147.705 20.4724C147.617 20.3374 147.576 20.2316 147.482 20.0907L146.585 18.74L148.18 18.2584C153.444 16.3145 155.713 12.7086 155.713 9.26721C155.713 4.71582 151.556 0 143.689 0C142.135 0 135.212 0.66362 133.828 0.66362C132.832 0.66362 131.612 0.663622 130.17 0.610767C130.117 0.886787 130.117 1.0571 130.059 1.49755C133.77 1.99674 133.992 2.05546 133.934 12.7615V24.7478C133.881 33.4042 133.271 33.9621 131.108 34.5729C130.111 34.8489 130 34.9077 130 35.2953C130 35.5713 130.223 35.6829 130.557 35.6829C130.832 35.6829 131.665 35.5713 132.773 35.5184C133.828 35.4068 135.153 35.354 136.431 35.354C136.877 35.354 138.096 35.4656 139.257 35.5772C140.365 35.6887 141.309 35.8003 141.526 35.8003C141.86 35.8003 141.913 35.4656 141.913 35.2483C141.913 35.031 141.801 35.0251 141.303 34.8607C138.864 34.1971 138.641 34.0855 138.202 33.14C137.592 31.6953 137.539 26.7563 137.539 24.924L137.592 18.4287C138.823 18.599 140.037 18.5931 141.256 18.6343C141.18 18.7047 141.221 18.7635 141.602 18.7635L144.703 23.2033C144.762 23.2914 144.815 23.3383 144.873 23.4206C149.387 29.7807 154.64 36 161.611 36C162.994 36 162.994 35.7768 162.994 35.5008" />
    </svg>
  )
}
