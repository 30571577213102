import { graphql, useStaticQuery } from 'gatsby'
import { useMemo } from 'react'

export const useLeaderQuery = (slug?: string | null) => {
  const { allDatoCmsLeader } =
    useStaticQuery<Queries.useLeaderQueryQuery>(graphql`
      query useLeaderQuery {
        allDatoCmsLeader {
          nodes {
            ...LeaderArticle
          }
        }
      }
    `)
  const leader = useMemo(
    () => allDatoCmsLeader.nodes.find(leader => leader.slug === slug),
    [slug, allDatoCmsLeader]
  )

  return leader
}
