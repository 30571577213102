import { css } from '@emotion/react'
import { ComponentPropsWithoutRef, Fragment } from 'react'

import { ExternalVideoBlock } from '@/components/ExternalVideoBlock'
import { GatsbyImageFocused } from '@/components/GatsbyImageFocused'
import { MediaCarousel } from '@/features/carousel'
import { absoluteFill, aspectRatio, mq } from '@/theme/mixins'

interface Props extends ComponentPropsWithoutRef<'div'> {
  data?: Queries.HighlightMultimediaSectionFragment['media'] | null
  textAlignment: 'LEFT' | 'RIGHT'
}

export const HighlightSectionMedia = ({
  data,
  textAlignment,
  ...props
}: Props): JSX.Element => {
  const styles = {
    media: css`
      position: relative;
      min-height: calc(
        (100vw - 2 * var(--margin) - var(--gtr-36)) * 5 / 12
      );
      order: ${textAlignment === 'LEFT' && 2};
      [data-gatsby-image-wrapper] {
        ${absoluteFill}
      }
      ${mq().ms} {
        order: 1;
        ${aspectRatio(3 / 2)}
      }
    `,
    videoContainer: css`
      background: rgba(0, 0, 0, 0.9);
      display: grid;
      position: relative;
      align-items: center;
    `,
  }
  return (
    <Fragment>
      {data?.map((media, i) => {
        switch (media?.__typename) {
          case 'DatoCmsImageBlock':
            return (
              <GatsbyImageFocused
                image={media.image?.gatsbyImageData}
                aspectRatio={3 / 2}
                data={media.image}
                css={styles.media}
                key={i}
                {...props}
              />
            )
          case 'DatoCmsExternalVideoBlock':
            return (
              <div css={styles.videoContainer}>
                <ExternalVideoBlock
                  data={media}
                  key={i}
                  playerProps={{ controls: true }}
                  {...props}
                />
              </div>
            )
          case 'DatoCmsMediaCarousel':
            return (
              <MediaCarousel
                data={media}
                layout="HIGHLIGHT"
                css={styles.media}
                key={i}
                {...props}
              />
            )
        }
      })}
    </Fragment>
  )
}
