import { css } from '@emotion/react'
import { graphql } from 'gatsby'
import { ComponentPropsWithoutRef, Fragment } from 'react'

import { colors } from '@/theme/variables'

interface Props extends ComponentPropsWithoutRef<'div'> {
  data?: Queries.BoardPersonFragment | null
}

export const BoardPerson = ({ data, ...props }: Props): JSX.Element => {
  const styles = {
    container: css`
      margin: 0.75em 0;
    `,
    name: css`
      font-size: var(--fs-21);
      font-weight: 400;
      line-height: 1.25;
      margin: 0;
    `,
    subheading: css`
      font-size: var(--fs-15);
      line-height: 1.333;
      margin: 0.333em 0;
      color: ${colors.gray85};
    `,
    title: css`
      display: inline-block;
    `,
    graduationYear: css`
      font-style: italic;
      display: inline-block;
    `,
  }
  return (
    <div
      css={styles.container}
      {...props}
    >
      <h4 css={styles.name}>{data?.name}</h4>
      <h5 css={styles.subheading}>
        <span css={styles.title}>{data?.title}</span>
        {data?.graduationYear && (
          <Fragment>
            &ensp;|&ensp;
            <span css={styles.graduationYear}>
              Class of {data?.graduationYear}
            </span>
          </Fragment>
        )}
      </h5>
    </div>
  )
}

export const BoardPersonFragment = graphql`
  fragment BoardPerson on DatoCmsBoardPerson {
    id: originalId
    __typename
    name
    title
    graduationYear
  }
`
