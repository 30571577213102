import { css } from '@emotion/react'
import { graphql } from 'gatsby'
import { ComponentPropsWithoutRef } from 'react'

import { ListItem } from './ListItem'

interface Props extends ComponentPropsWithoutRef<'ul'> {
  data?: Queries.ListFragment | null
  headingLevel?: 2 | 3 | 4 | 5 | 6
}

export const List = ({
  data,
  headingLevel,
  ...props
}: Props): JSX.Element => {
  const styles = {
    list: css`
      list-style: none;
      appearance: none;
      margin: 0;
      padding: 0;
    `,
  }
  return (
    <ul
      css={styles.list}
      {...props}
    >
      {data?.listItems?.map(item => (
        <ListItem
          data={item}
          key={item?.id}
          headingLevel={headingLevel}
        />
      ))}
    </ul>
  )
}

export const ListFragment = graphql`
  fragment List on DatoCmsList {
    __typename
    id: originalId
    listItems {
      ...ListItem
    }
  }
`
