import { graphql, useStaticQuery } from 'gatsby'
import { useMemo } from 'react'

export const useAlumnaeArticleQuery = (slug?: string | null) => {
  const { allDatoCmsAlumnaeArticle } =
    useStaticQuery<Queries.useAlumnaeArticleQueryQuery>(graphql`
      query useAlumnaeArticleQuery {
        allDatoCmsAlumnaeArticle {
          nodes {
            ...AlumnaeArticle
          }
        }
      }
    `)
  const article = useMemo(
    () =>
      allDatoCmsAlumnaeArticle.nodes.find(
        article => article.slug === slug
      ),
    [slug, allDatoCmsAlumnaeArticle]
  )

  return article
}
