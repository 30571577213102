import { Global, css } from '@emotion/react'

export const ScrollToggle = (): JSX.Element => {
  const styles = css`
    html {
      overflow: hidden;
    }
  `
  return <Global styles={styles} />
}
