import { css } from '@emotion/react'
import { graphql } from 'gatsby'
import { ComponentPropsWithoutRef } from 'react'

import { Deco } from '@/components/Deco'
import { GatsbyImageFocused } from '@/components/GatsbyImageFocused'
import { absoluteFill, mq, scrim } from '@/theme/mixins'

interface Props extends ComponentPropsWithoutRef<'section'> {
  heading?: string | null
  heroImage?: Queries.PageHeroFragment | null
}

export const PageHero = ({
  heading,
  heroImage,
  ...props
}: Props): JSX.Element => {
  const styles = {
    section: css`
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      position: relative;
      padding: var(--row-144) var(--margin);
      min-height: 36vw;
      box-sizing: border-box;
      width: 100vw;
      overflow: hidden;
      border: 0.5rem solid #fff;
    `,
    image: css`
      ${absoluteFill}
      background: #000;
      [data-gatsby-image-wrapper] {
        opacity: 0.875;
      }
      &:after {
        content: '';
        ${absoluteFill};
        background: ${scrim()};
        opacity: 0.25;
      }
    `,
    heading: css`
      position: relative;
      font-size: var(--fs-84);
      text-transform: uppercase;
      max-width: min(27ch, 100%);
      text-align: center;
      margin: 0.333em 0;
      text-shadow: 0 0.025em 0.1em #00000066;
      color: #fff;
      /* hyphens: auto; */
      /* overflow-wrap: ; */
      ${mq().s} {
        font-size: var(--fs-60);
      }
    `,
    deco: css`
      width: 1.5em;
    `,
  }
  return (
    <section
      css={styles.section}
      {...props}
    >
      <GatsbyImageFocused
        css={styles.image}
        image={heroImage?.gatsbyImageData}
        aspectRatio={7 / 4}
        data={heroImage}
      />
      <h1
        css={styles.heading}
        lang="en"
      >
        {heading}
      </h1>
      <Deco
        fill="#ffffff"
        css={styles.deco}
      />
    </section>
  )
}

export const PageHeroFragment = graphql`
  fragment PageHero on DatoCmsFileField {
    gatsbyImageData(
      layout: FULL_WIDTH
      width: 1920
      imgixParams: { ar: "7:4", fit: "crop", crop: "focalpoint" }
    )
    ...ImageFocalData
  }
`
