import { css } from '@emotion/react'
import { useEffect } from 'react'
import { useInView } from 'react-intersection-observer'

import { mq } from '@/theme/mixins'
import { colors } from '@/theme/variables'

import { useMissionScrollContext } from '../../contexts/missionScrollContext'
import { TextBlock } from './TextBlock'

interface Props {
  textBlock?: Queries.MissionTextFragment | null
}

export const Text2 = ({ textBlock }: Props): JSX.Element => {
  const { addViewZone, removeViewZone } = useMissionScrollContext()
  const { inView: inView3, ref: ref3 } = useInView()
  const { inView: inView4, ref: ref4 } = useInView()
  const { inView: inView5, ref: ref5 } = useInView()
  const { inView: inView6, ref: ref6 } = useInView()
  const { inView: inView7, ref: ref7 } = useInView()
  useEffect(() => {
    inView3 ? addViewZone(3) : removeViewZone(3)
    inView4 ? addViewZone(4) : removeViewZone(4)
    inView5 ? addViewZone(5) : removeViewZone(5)
    inView6 ? addViewZone(6) : removeViewZone(6)
    inView7 ? addViewZone(7) : removeViewZone(7)
  }, [
    inView3,
    inView4,
    inView5,
    inView6,
    inView7,
    addViewZone,
    removeViewZone,
  ])

  const styles = {
    section: css`
      box-sizing: border-box;
      display: grid;
      grid-template-columns: repeat(12, 1fr);
      gap: var(--gtr);
      padding: 0 var(--margin);
      justify-content: center;
      align-items: flex-end;
      color: #fff;
    `,
    zone: css`
      grid-column: 1 / -1;
      height: max(8rem, 15vh);
    `,
    textBlock: css`
      grid-column: span 8 / -1;
      max-width: 20ch;
      justify-self: center;
      padding: 0 var(--margin) 90vh;
      ${mq().s} {
        grid-column: 1 / -1;
        max-width: 100%;
      }
      a {
        @media (hover: hover) {
          &:hover {
            color: ${colors.red};
          }
        }
      }
    `,
  }
  return (
    <section css={styles.section}>
      <div
        ref={ref3}
        css={styles.zone}
      />
      <div
        ref={ref4}
        css={styles.zone}
      />
      <div
        ref={ref5}
        css={styles.zone}
      />
      <div
        ref={ref6}
        css={styles.zone}
      />
      <TextBlock
        ref={ref7}
        textBlock={textBlock}
        css={styles.textBlock}
        decoColor={colors.red}
      />
    </section>
  )
}
