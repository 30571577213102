import { css } from '@emotion/react'
import { ComponentPropsWithoutRef } from 'react'

export const SearchIcon = ({
  ...props
}: ComponentPropsWithoutRef<'svg'>): JSX.Element => {
  const styles = {
    svg: css`
      display: flex;
      path {
        fill: transparent;
        stroke: currentColor;
        stroke-width: 2;
        stroke-miterlimit: 10;
        stroke-linecap: round;
      }
    `,
  }
  return (
    <svg
      css={styles.svg}
      viewBox="0 0 24 24"
      {...props}
    >
      <path
        vectorEffect={'non-scaling-stroke'}
        d="M14 19C18.9706 19 23 14.9706 23 10C23 5.02944 18.9706 1 14 1C9.02944 1 5 5.02944 5 10C5 14.9706 9.02944 19 14 19Z"
      />
      <path
        vectorEffect={'non-scaling-stroke'}
        d="M7 17L1 23"
      />
    </svg>
  )
}
