import { graphql, useStaticQuery } from 'gatsby'
import { useMemo } from 'react'

export const useBlackbaudFormQuery = (slug?: string | null) => {
  const { allDatoCmsBlackbaudForm } =
    useStaticQuery<Queries.useBlackbaudFormQueryQuery>(graphql`
      query useBlackbaudFormQuery {
        allDatoCmsBlackbaudForm {
          nodes {
            ...BlackbaudForm
          }
        }
      }
    `)

  const form = useMemo(
    () =>
      allDatoCmsBlackbaudForm.nodes.find(
        article => article.slug === slug
      ),
    [slug, allDatoCmsBlackbaudForm]
  )

  return form
}
