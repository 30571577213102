import { css } from '@emotion/react'
import { graphql } from 'gatsby'
import { type ComponentProps } from 'react'

import { ExternalVideoBlock } from '@/components/ExternalVideoBlock'
import { colors } from '@/theme/variables'

interface Props extends ComponentProps<'article'> {
  data: Queries.LandingTestimonialArticleFragment | null
  layout: 'PAGE' | 'LIGHTBOX'
}

export const LandingTestimonialArticle = ({
  data,
  layout,
  ...props
}: Props) => {
  const styles = {
    container: css`
      padding: 0.75rem;
      background: #fff;
      ${layout === 'PAGE' &&
      css`
        border: 1.5rem solid ${colors.red};
        border-top: 0;
        max-height: calc(100vh - var(--nav-height-min));
        display: flex;
        box-sizing: border-box;
      `}
    `,
    block: css`
      width: 100%;
    `,
  }
  return (
    <article
      css={styles.container}
      {...props}
    >
      <ExternalVideoBlock
        css={styles.block}
        data={{ video: data?.video }}
        playerProps={{ controls: true }}
      />
    </article>
  )
}

export const LandingTestimonialArticleFragment = graphql`
  fragment LandingTestimonialArticle on DatoCmsLandingTestimonial {
    __typename
    id: originalId
    video {
      url
      thumbnailUrl
      title
      width
      height
    }
    slug
    seoMetaTags {
      tags
    }
  }
`
