import { css } from '@emotion/react'
import { useEffect } from 'react'
import { useInView } from 'react-intersection-observer'

import { mq } from '@/theme/mixins'
import { colors } from '@/theme/variables'

import { useMissionScrollContext } from '../../contexts/missionScrollContext'
import { TextBlock } from './TextBlock'

interface Props {
  textBlock?: Queries.MissionTextFragment | null
}

export const Text1 = ({ textBlock }: Props): JSX.Element => {
  const { addViewZone, removeViewZone } = useMissionScrollContext()
  const { inView: inView1, ref: ref1 } = useInView()
  const { inView: inView2, ref: ref2 } = useInView()
  useEffect(() => {
    inView1 ? addViewZone(1) : removeViewZone(1)
    inView2 ? addViewZone(2) : removeViewZone(2)
  }, [inView1, inView2, addViewZone, removeViewZone])
  const styles = {
    section: css`
      display: grid;
      justify-content: center;
      align-items: center;
      color: #fff;
      padding: 0 var(--margin);
    `,
    zone: css`
      flex: 1;
      height: 25vh;
      ${mq().s} {
        height: 10vh;
      }
    `,
    textBlock: css`
      padding: 0 var(--margin) 90vh;
      max-width: 25ch;
      text-align: center;
      ${mq().s} {
        text-align: left;
        max-width: 100%;
      }
      a {
        @media (hover: hover) {
          &:hover {
            color: ${colors.red};
          }
        }
      }
    `,
  }
  return (
    <section css={styles.section}>
      <div
        ref={ref1}
        css={styles.zone}
      />
      <TextBlock
        ref={ref2}
        textBlock={textBlock}
        css={styles.textBlock}
        decoColor={colors.red}
      />
    </section>
  )
}
