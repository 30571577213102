import { css } from '@emotion/react'
import { graphql, useStaticQuery } from 'gatsby'
import { ComponentPropsWithoutRef } from 'react'

type Props = ComponentPropsWithoutRef<'div'>

export const LogInLink = ({ ...props }: Props): JSX.Element => {
  const { datoCmsMainNav } = useStaticQuery(graphql`
    query LogInLink {
      datoCmsMainNav {
        loginUrl
      }
    }
  `)

  const styles = {
    container: css`
      width: calc(2rem + 1.5em);
    `,
    link: css`
      position: relative;
      padding: 1rem;
      width: 1.5em;
      height: 1.5em;
      display: grid;
      font-size: var(--fs-16);
      &:before {
        content: '';
        position: absolute;
        grid-area: 1 / 1 / 2 / 2;
        border: 2px solid #fff;
        border-radius: 0.75em;
        box-sizing: border-box;
        width: 1.5em;
        height: 1.5em;
        left: 50%;
        top: 50%;
        transform: translate(-50%, -50%);
        transition: all 200ms ease;
      }
      @media (hover: hover) {
        &:hover:before {
          font-size: calc(var(--fs-18) * 1.125);
          width: calc(2rem + 1.5em);
        }
      }
    `,
    logIn: css`
      grid-area: 1 / 1 / 2 / 2;
      position: absolute;
      width: max-content;
      font-size: var(--fs-13);
      color: #fff;
      line-height: 1;
      font-weight: 700;
      align-self: center;
      justify-self: center;
      opacity: 0;
      transform: translate3d(0, -0.5rem, 0);
      transition: all 200ms ease;
      @media (hover: hover) {
        a:hover > & {
          opacity: 1;
          transform: translate3d(0, 0, 0);
        }
      }
    `,
    svg: css`
      fill: transparent;
      grid-area: 1 / 1 / 2 / 2;
      transition: all 200ms ease;
      width: calc(100% - 2px);
      height: calc(100% - 2px);
      margin: 1px;
      path {
        stroke: #fff;
        stroke-width: 2px;
        stroke-miterlimit: 10;
        stroke-linecap: round;
      }
      @media (hover: hover) {
        a:hover > & {
          opacity: 0;
          transform: translate3d(0, 0.5rem, 0);
        }
      }
    `,
  }
  return (
    <div
      css={styles.container}
      {...props}
    >
      <a
        href={datoCmsMainNav?.loginUrl}
        aria-label="Log In"
        target="_blank"
        rel="noreferrer"
        css={styles.link}
      >
        <span css={styles.logIn}>Log In</span>
        <svg
          viewBox="0 0 24 24"
          css={styles.svg}
        >
          <path
            vectorEffect={'non-scaling-stroke'}
            d="M19.7063 19.849C18.1298 17.2424 15.2682 15.5 11.9996 15.5C8.73106 15.5 5.86945 17.2424 4.29297 19.849"
          />
          <path
            vectorEffect={'non-scaling-stroke'}
            d="M12 12C13.933 12 15.5 10.433 15.5 8.5C15.5 6.567 13.933 5 12 5C10.067 5 8.5 6.567 8.5 8.5C8.5 10.433 10.067 12 12 12Z"
          />
        </svg>
      </a>
    </div>
  )
}
