import { graphql, useStaticQuery } from 'gatsby'
import { useMemo } from 'react'

export const useLocalFileUrl = (localFileId?: string | null) => {
  const { allFile } = useStaticQuery<Queries.useLocalFileUrlQuery>(
    graphql`
      query useLocalFileUrl {
        allFile(filter: { extension: { in: ["pdf", "doc", "docx"] } }) {
          nodes {
            id
            publicURL
          }
        }
      }
    `
  )
  const matchedNode = useMemo(() => {
    return allFile.nodes?.find(node => node?.id === localFileId)
  }, [allFile, localFileId])

  return matchedNode?.publicURL
}
