import { css } from '@emotion/react'
import { graphql, useStaticQuery } from 'gatsby'
import { ComponentPropsWithoutRef, useMemo } from 'react'

import {
  ArticleContainer,
  ArticleHeading,
  ArticleHeroCarousel,
  ArticleLayoutOptions,
} from '@/features/article-sections'

import { AthleticsTeamCoaches } from './AthleticsTeamCoaches'
import { AthleticsTeamSchedule } from './AthleticsTeamSchedule'

export type TeamSeasonOption = 'FALL' | 'WINTER' | 'SPRING'

interface Props extends ComponentPropsWithoutRef<'article'> {
  data?: Queries.AthleticsTeamArticleFragment | null
  layout: ArticleLayoutOptions
}

export const AthleticsTeamArticle = ({
  data,
  layout,
  ...props
}: Props): JSX.Element => {
  const { athleticCalendarEvents } =
    useStaticQuery<Queries.AthleticsTeamScheduleQuery>(graphql`
      query AthleticsTeamSchedule {
        athleticCalendarEvents {
          events {
            ...AthleticCalendarEvent
          }
        }
      }
    `)
  const { events } = athleticCalendarEvents || {}
  const scheduleData = useMemo(
    () =>
      events?.reduce(
        (acc, event) => {
          if (event?.teamId === data?.bbTeamId) {
            acc.events.push(event)
            switch (event?.winOrLoss) {
              case 'Win':
                acc.wins += 1
                break
              case 'Loss':
                acc.losses += 1
                break
              case 'Tie':
                acc.ties += 1
                break
            }
          }
          return acc
        },
        {
          events:
            [] as (Queries.AthleticCalendarEventFragment | null)[],
          wins: 0,
          losses: 0,
          ties: 0,
        }
      ),
    [events, data?.bbTeamId]
  )
  const styles = {
    subheading: css`
      display: flex;
      gap: 1em;
    `,
  }
  return (
    <ArticleContainer
      layout={layout}
      hero={<ArticleHeroCarousel data={data?.hero?.[0]} />}
      {...props}
    >
      <ArticleHeading
        heading={data?.teamName}
        subheading={
          <div css={styles.subheading}>
            <span>Wins: {scheduleData?.wins}</span>
            <span>Losses: {scheduleData?.losses}</span>
            <span>Ties: {scheduleData?.ties}</span>
          </div>
        }
      />
      <AthleticsTeamCoaches data={data?.coaches} />
      <AthleticsTeamSchedule
        events={scheduleData?.events}
        layout={layout}
      />
    </ArticleContainer>
  )
}

export const AthleticsTeamArticleFragment = graphql`
  fragment AthleticsTeamArticle on DatoCmsAthleticsTeam {
    id: originalId
    __typename
    teamName
    bbTeamId
    hero {
      ...ArticleHeroCarousel
    }
    season
    division {
      divisionName
    }
    coaches {
      ...Coach
    }
    seoMetaTags {
      tags
    }
    slug
  }
`
