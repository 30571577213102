import { css } from '@emotion/react'
import { graphql } from 'gatsby'
import { ComponentPropsWithoutRef, Fragment } from 'react'

import { DatoLink } from '@/features/dato-link'
import { mq } from '@/theme/mixins'
import { colors } from '@/theme/variables'

interface Props extends ComponentPropsWithoutRef<'div'> {
  data?: Queries.LinksListFragment | null
}

export const LinksList = ({ data, ...props }: Props): JSX.Element => {
  const { showLabel, label, links } = data || {}
  const styles = {
    label: css`
      font-size: var(--fs-24);
      font-family: var(--ff-display);
      font-weight: 400;
      color: ${colors.gray20};
      margin: 1.25em 0 0.5em;
      text-transform: uppercase;
      ${mq().ls} {
        font-family: var(--ff-display-optical);
      }
    `,
    linksList: css`
      && {
        margin: 0;
        padding: 0;
        list-style: none;
        li {
          margin: 0;
          padding: 0;
        }
      }
    `,
    link: css`
      margin: 0.375em 0;
      color: ${colors.red};
      text-transform: ${!showLabel && 'uppercase'};
      @media (hover: hover) {
        &:hover {
          color: ${colors.redDark};
        }
      }
    `,
  }
  if (links?.length) {
    return (
      <div {...props}>
        {showLabel && label && <h3 css={styles.label}>{label}</h3>}
        <ul css={styles.linksList}>
          {links.map((link, i) => (
            <li key={i}>
              <DatoLink
                css={styles.link}
                data={link}
                showIcon
                styleVariant="LOWERCASE"
              />
            </li>
          ))}
        </ul>
      </div>
    )
  }
  return <Fragment />
}

export const LinksListFragment = graphql`
  fragment LinksList on DatoCmsLinksList {
    __typename
    id: originalId
    showLabel
    label
    links {
      ... on DatoCmsPageLink {
        ...PageLink
      }
      ... on DatoCmsExternalLink {
        ...ExternalLink
      }
      ... on DatoCmsTertiaryArticleLink {
        ...TertiaryArticleLink
      }
      ... on DatoCmsPdfLink {
        ...PdfLink
      }
      ... on DatoCmsFormLink {
        ...FormLink
      }
    }
  }
`
