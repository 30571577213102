import { css } from '@emotion/react'
import { graphql } from 'gatsby'
import { ComponentPropsWithoutRef, ElementType } from 'react'

interface Props extends ComponentPropsWithoutRef<'div'> {
  data?: Queries.AlumnaFragment | null
  headingLevel: 3 | 4
}

export const Alumna = ({
  data,
  headingLevel,
  ...props
}: Props): JSX.Element => {
  const Heading = `h${headingLevel}` as ElementType
  const styles = {
    container: css`
      font-size: var(--fs-18);
      width: 100%;
      margin: 0 0 0.5em;
      display: inline-block;
    `,
    name: css`
      margin: 0;
      font-family: var(--ff-body);
      font-size: inherit;
      line-height: 1.333;
    `,
    graduationYear: css`
      line-height: 1.333;
      font-size: var(--fs-15);
      font-style: italic;
      font-weight: 450;
      color: rgba(0, 0, 0, 0.55);
    `,
  }
  return (
    <div
      css={styles.container}
      {...props}
    >
      <Heading css={styles.name}>{data?.name}</Heading>
      <div css={styles.graduationYear}>
        Class of {data?.graduationYear}
      </div>
    </div>
  )
}

export const AlumnaFragment = graphql`
  fragment Alumna on DatoCmsAlumna {
    __typename
    id: originalId
    name
    graduationYear
    title
  }
`
