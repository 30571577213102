import { css } from '@emotion/react'
import { graphql } from 'gatsby'
import { ComponentPropsWithoutRef } from 'react'

import { DetailBlock } from '@/components/DetailBlock'
import { mq } from '@/theme/mixins'

interface Props extends ComponentPropsWithoutRef<'div'> {
  data?: Queries.DirectionsBlockFragment | null
}

export const DirectionsBlock = ({
  data,
  ...props
}: Props): JSX.Element => {
  const styles = {
    block: css`
      display: grid;
      grid-template-columns: 1fr 2fr;
      --border-thickness: 1rem;
      grid-column-gap: calc(var(--gtr-36) + var(--border-thickness));
      align-items: center;
      margin: var(--row-54) 0;
      ${mq().s} {
        grid-template-columns: 1fr;
        grid-row-gap: calc(var(--row-36) + var(--border-thickness));
        --border-thickness: 0.75rem;
      }
    `,
    graphic: css`
      position: relative;
      align-self: flex-start;
      display: flex;
      img {
        position: relative;
        width: 100%;
      }
      &:before {
        content: '';
        position: absolute;
        top: calc(-1 * var(--border-thickness));
        left: calc(-1 * var(--border-thickness));
        width: calc(100% + 2 * var(--border-thickness));
        height: calc(100% + 2 * var(--border-thickness));
        background: rgba(0, 0, 0, 0.05);
      }
    `,
    details: css`
      display: grid;
      gap: var(--row-36);
    `,
  }
  return (
    <div
      css={styles.block}
      {...props}
    >
      <div css={styles.graphic}>
        <img
          src={data?.mapGraphic?.url || undefined}
          alt={data?.mapGraphic?.alt || ''}
        />
      </div>
      <div css={styles.details}>
        {data?.details?.map((detail, i) => (
          <DetailBlock
            data={detail}
            key={i}
          />
        ))}
      </div>
    </div>
  )
}

export const DirectionsBlockFragment = graphql`
  fragment DirectionsBlock on DatoCmsDirectionsBlock {
    __typename
    id: originalId
    mapGraphic {
      url
      alt
    }
    details {
      ...DetailBlock
    }
  }
`
