import { css } from '@emotion/react'
import { graphql } from 'gatsby'
import { ComponentPropsWithRef, forwardRef } from 'react'

import { DatoStructuredText } from '@/components/DatoStructuredText'
import { Deco } from '@/components/Deco'
import { LightboxLink } from '@/features/lightbox'
import { mq } from '@/theme/mixins'

interface Props extends ComponentPropsWithRef<'div'> {
  textBlock?: Queries.MissionTextFragment | null
  decoColor?: string
}

export const TextBlock = forwardRef<HTMLDivElement, Props>(
  ({ textBlock, decoColor, ...props }, ref): JSX.Element => {
    const styles = {
      container: css`
        font-size: var(--fs-72);
        font-family: var(--ff-display);
        display: grid;
        justify-items: center;
        ${mq().ml} {
          font-size: var(--fs-60);
        }
        ${mq().ms} {
          font-size: var(--fs-48);
        }
        ${mq().s} {
          font-size: var(--fs-54);
        }
        p {
          line-height: 1.125;
          margin: 0.667em 0;
        }
        a {
          /* display: inline-block; */
          color: inherit;
          text-decoration: underline;
          text-decoration-thickness: 2px;
          text-underline-offset: 0.125em;
          transition: color 300ms ease;
          ${mq().ms} {
            text-decoration-thickness: 1.5px;
          }
        }
      `,
    }
    return (
      <div
        css={styles.container}
        {...props}
        ref={ref}
      >
        <Deco
          fill={decoColor}
          flip
        />
        <div>
          <DatoStructuredText
            data={textBlock?.text}
            renderLinkToRecord={({
              record,
              children,
              transformedMeta,
            }) => {
              if (record.__typename === 'DatoCmsMissionArticle') {
                return (
                  <LightboxLink
                    data={record as Queries.MissionArticleFragment}
                    {...transformedMeta}
                  >
                    {children}
                  </LightboxLink>
                )
              }
              return (
                <a
                  href="/"
                  {...transformedMeta}
                >
                  {children}
                </a>
              )
            }}
          />
        </div>
        <Deco fill={decoColor} />
      </div>
    )
  }
)

export const MissionTextFragment = graphql`
  fragment MissionText on DatoCmsMissionText {
    __typename
    id: originalId
    text {
      value
      links {
        ...MissionArticle
      }
    }
  }
`

TextBlock.displayName = 'TextBlock'
