import { css, keyframes } from '@emotion/react'
import {
  ComponentPropsWithoutRef,
  useCallback,
  useEffect,
  useState,
} from 'react'

export const ScrollHintArrow = ({
  ...props
}: ComponentPropsWithoutRef<'div'>): JSX.Element => {
  const [scrolled, setScrolled] = useState(false)
  const handleScroll = useCallback(() => {
    requestAnimationFrame(() => {
      if (window.scrollY > 80) {
        setScrolled(true)
      }
    })
  }, [])
  useEffect(() => {
    window.addEventListener('scroll', handleScroll, { passive: true })
    !scrolled && handleScroll()
    if (scrolled) {
      window.removeEventListener('scroll', handleScroll)
    }
    return () => {
      window.removeEventListener('scroll', handleScroll)
    }
  }, [handleScroll, scrolled])
  const fadeMoveDown = keyframes`
    0%   { transform:translate(0,-1em); opacity: 0;  }
    50%  { opacity: 1;  }
    100% { transform:translate(0,1em); opacity: 0; }
  `
  const styles = {
    container: css`
      transition:
        opacity 1000ms ease-out,
        visibility 0ms 1000ms linear;
      ${scrolled &&
      css`
        opacity: 0;
        visibility: hidden;
      `}
    `,
    svg: css`
      width: 3em;
      height: auto;
      opacity: 0;
      animation: ${fadeMoveDown} 2000ms ease-out infinite 2500ms
        forwards;
      path {
        stroke: #fff;
        fill: transparent;
        stroke-width: 3;
      }
    `,
  }
  return (
    <div
      css={styles.container}
      {...props}
    >
      <svg
        viewBox="0 0 40 23"
        css={styles.svg}
      >
        <path d="M2 2L18.8665 20L38 2" />
      </svg>
    </div>
  )
}
