import { Link, graphql } from 'gatsby'
import { ComponentPropsWithoutRef } from 'react'

import { DatoLinkIcon } from './DatoLinkIcon'

interface Props extends ComponentPropsWithoutRef<'a'> {
  data?: Queries.PageLinkFragment | null
  showIcon?: boolean
}

export const PageLink = ({
  data,
  showIcon,
  ...props
}: Props): JSX.Element => {
  return (
    <Link
      to={`/${data?.page?.slug}${data?.page?.slug ? '/' : ''}`}
      {...props}
    >
      <span>{data?.linkText}</span>
      {showIcon && <DatoLinkIcon iconType="ARROW" />}
    </Link>
  )
}

export const PageLinkFragment = graphql`
  fragment PageLink on DatoCmsPageLink {
    id: originalId
    __typename
    linkText
    page {
      ... on DatoCmsMissionPage {
        id: originalId
        slug
      }
      ... on DatoCmsHomePage {
        id: originalId
        slug
      }
      ... on DatoCmsDivisionPage {
        id: originalId
        slug
      }
      ... on DatoCmsTourPage {
        id: originalId
        slug
      }
      ... on DatoCmsStoriesPage {
        id: originalId
        slug
      }
      ... on DatoCmsNewsPage {
        id: originalId
        slug
      }
      ... on DatoCmsWelcomePage {
        id: originalId
        slug
      }
      ... on DatoCmsAthleticsWelcomePage {
        id: originalId
        slug
      }
      ... on DatoCmsInteriorPage {
        id: originalId
        slug
      }
      ... on DatoCmsAthleticFacilitiesPage {
        id: originalId
        slug
      }
      ... on DatoCmsAthleticsTeamsSchedulesPage {
        id: originalId
        slug
      }
      ... on DatoCmsNewsletterPage {
        id: originalId
        slug
      }
      ... on DatoCmsAthleticsAwardsPage {
        id: originalId
        slug
      }
      ... on DatoCmsOurPeoplePage {
        id: originalId
        slug
      }
      ... on DatoCmsDirectoryPage {
        id: originalId
        slug
      }
      ... on DatoCmsGivingPage {
        id: originalId
        slug
      }
      ... on DatoCmsDivisionAdmissionsPage {
        id: originalId
        slug
      }
      ... on DatoCmsAdmissionsWelcomePage {
        id: originalId
        slug
      }
      ... on DatoCmsAdmissionsEventsPage {
        id: originalId
        slug
      }
      ... on DatoCmsAdmissionsFaqPage {
        id: originalId
        slug
      }
      ... on DatoCmsTuitionPage {
        id: originalId
        slug
      }
      ... on DatoCmsContactAndDirectionsPage {
        id: originalId
        slug
      }
      ... on DatoCmsAlumnaeAssociationPage {
        id: originalId
        slug
      }
      ... on DatoCmsAlumnaeAwardsPage {
        id: originalId
        slug
      }
      ... on DatoCmsAlumnaeEventsPage {
        id: originalId
        slug
      }
      ... on DatoCmsAlumnaeArticlesPage {
        id: originalId
        slug
      }
      ... on DatoCmsAlumnaeDirectoryPage {
        id: originalId
        slug
      }
      ... on DatoCmsStrategicVisionPage {
        id: originalId
        slug
      }
      ... on DatoCmsHistoryPage {
        id: originalId
        slug
      }
      ... on DatoCmsStatsPage {
        id: originalId
        slug
      }
      ... on DatoCmsAcademicCalendarPage {
        id: originalId
        slug
      }
      ... on DatoCmsLandingPage {
        __typename
        id: originalId
      }
    }
  }
`
