import { css } from '@emotion/react'
import { ComponentPropsWithoutRef } from 'react'

import { bezier } from '@/theme/mixins'
import { colors } from '@/theme/variables'

import { useMainNavContext } from '../../contexts/mainNavContext'

interface Props extends ComponentPropsWithoutRef<'div'> {
  navItems?: readonly (Queries.MainNavItemFragment | null)[] | null
  searchIsOpen: boolean
  breakpoint?: NonNullable<
    Queries.MainNavQuery['datoCmsMainNav']
  >['breakpoint']
}

export const NavList = ({
  navItems,
  searchIsOpen,
  breakpoint,
  ...props
}: Props): JSX.Element => {
  const { activeNavItemId, setActiveNavItemId, mobileNavIsOpen } =
    useMainNavContext()
  const handleButtonClick = (navItemId?: string) => {
    // window.scrollTo(0, 0)
    setActiveNavItemId(navItemId)
  }
  const styles = {
    navList: css`
      pointer-events: all;
      order: 3;
      flex: none;
      display: flex;
      color: #fff;
      justify-content: flex-end;
      gap: 1.5em;
      margin-left: 1.5em;
      transition: opacity 200ms ease 200ms;
      ${searchIsOpen &&
      css`
        transition-delay: 0ms;
        opacity: 0;
        pointer-events: none;
      `}
      @media (max-width: ${breakpoint}px) {
        display: grid;
        position: fixed;
        width: 100%;
        height: calc(100% - var(--nav-height-flex));
        top: 0;
        left: 0;
        margin: var(--nav-height-flex) 0 0;
        padding: var(--row-108) var(--margin);
        box-sizing: border-box;
        justify-content: center;
        justify-items: center;
        align-content: center;
        overflow: auto;
        gap: 0.5em;
        background: #fff;
        color: ${colors.gray20};
        transition:
          opacity 300ms ${bezier.easeOut} 300ms,
          transform 400ms ${bezier.easeOut},
          background-color 600ms ${bezier.easeOut};
        opacity: 0;
        transform: translate3d(0, -100%, 0);
        pointer-events: none;
        ${mobileNavIsOpen &&
        css`
          pointer-events: all;
          transition-delay: 0ms;
          opacity: 1;
          transform: translate3d(0, 0, 0);
        `};
        ${activeNavItemId &&
        css`
          background: ${colors.gray20};
          transform: translate3d(-100%, 0, 0);
          transition-duration: 300ms, 800ms;
        `}
      }
    `,
    navListButton: (active: boolean) => css`
      position: relative;
      font-size: var(--fs-15);
      text-transform: uppercase;
      letter-spacing: 0.1em;
      font-weight: 475;
      padding: 0.75em 0;
      &:after {
        content: '';
        position: absolute;
        bottom: 0.25em;
        left: 50%;
        transform: translateX(-50%);
        height: 2px;
        background: currentColor;
        width: ${active ? '100%' : '0%'};
        transition: width 300ms ease;
      }
      @media (hover: hover) {
        &:hover {
          &:after {
            width: 100%;
          }
        }
      }
      @media (max-width: ${breakpoint}px) {
        font-size: var(--fs-24);
        text-transform: none;
        letter-spacing: 0;
        font-weight: 350;
        &:after {
          display: none;
        }
      }
    `,
  }
  return (
    <div
      css={styles.navList}
      {...props}
    >
      {navItems?.map((navItem, i) => (
        <button
          css={() =>
            styles.navListButton(navItem?.id === activeNavItemId)
          }
          key={i}
          onClick={() => handleButtonClick(navItem?.id)}
        >
          {navItem?.label}
        </button>
      ))}
    </div>
  )
}
