import {
  Dispatch,
  ReactNode,
  SetStateAction,
  createContext,
  useContext,
  useEffect,
  useRef,
  useState,
} from 'react'

type INavItemId = string | null | undefined

interface IContext {
  activeNavItemId: INavItemId
  setActiveNavItemId: Dispatch<SetStateAction<INavItemId>>
  prevActiveNavItemId: INavItemId
  mobileNavIsOpen: boolean
  setMobileNavIsOpen: Dispatch<SetStateAction<boolean>>
}

const MainNavContext = createContext<IContext | undefined>(undefined)

export const useMainNavContext = () =>
  useContext(MainNavContext) as IContext

export const MainNavContextProvider = ({
  children,
}: {
  children: ReactNode
}) => {
  const [activeNavItemId, setActiveNavItemId] =
    useState<INavItemId>(null)
  const prevActiveNavItemId = useRef<INavItemId>(null)
  useEffect(() => {
    prevActiveNavItemId.current = activeNavItemId
  }, [activeNavItemId])

  const [mobileNavIsOpen, setMobileNavIsOpen] = useState(false)

  return (
    <MainNavContext.Provider
      value={{
        activeNavItemId,
        prevActiveNavItemId: prevActiveNavItemId.current,
        setActiveNavItemId: value => setActiveNavItemId(value),
        mobileNavIsOpen,
        setMobileNavIsOpen: value => setMobileNavIsOpen(value),
      }}
    >
      {children}
    </MainNavContext.Provider>
  )
}
