import { css } from '@emotion/react'
import { graphql } from 'gatsby'
import {
  ComponentPropsWithoutRef,
  Dispatch,
  SetStateAction,
  useEffect,
} from 'react'
import { useInView } from 'react-intersection-observer'

import { toSlug } from '@/helpers'
import { mq } from '@/theme/mixins'
import { colors } from '@/theme/variables'

import { DirectoryPerson } from './DirectoryPerson'

interface Props extends ComponentPropsWithoutRef<'section'> {
  data?: Queries.DirectorySectionFragment | null
  setActiveSectionId: Dispatch<SetStateAction<string | undefined>>
}

export const DirectorySection = ({
  data,
  setActiveSectionId,
  ...props
}: Props): JSX.Element => {
  const { inView, ref } = useInView({
    rootMargin: '-40% 0% -60%',
  })
  useEffect(() => {
    inView && setActiveSectionId(data?.id)
  }, [inView, setActiveSectionId, data?.id])
  const styles = {
    section: css`
      position: relative;
    `,
    anchor: css`
      position: absolute;
      top: calc(-1 * var(--nav-height-min) - 2em);
      ${mq().s} {
        top: calc(-1 * var(--nav-height-min) - 4em);
      }
    `,
    heading: css`
      font-size: var(--fs-60);
      padding: 1em 0 0;
      margin: 1em 0 0;
      padding: 0;
      margin: 0;
      &:after {
        content: '';
        display: block;
        width: 1em;
        height: 2px;
        background: ${colors.red};
        margin: 0.5em 0 0.75em;
      }
    `,
    list: css`
      list-style: none;
      margin: 0;
      padding: 0;
      display: grid;
      grid-template-columns: 1fr 1fr 1fr;
      grid-column-gap: 2em;
      grid-row-gap: 3em;
      ${mq().ms} {
        grid-template-columns: 1fr 1fr;
        grid-row-gap: 2em;
      }
    `,
  }
  return (
    <section
      css={styles.section}
      ref={ref}
      {...props}
    >
      <div
        css={styles.anchor}
        id={toSlug(data?.heading)}
      />
      <h2 css={styles.heading}>{data?.heading}</h2>
      <ul css={styles.list}>
        {data?.people?.map((person, i) => (
          <li key={i}>
            <DirectoryPerson data={person} />
          </li>
        ))}
      </ul>
    </section>
  )
}

export const DirectorySectionFragment = graphql`
  fragment DirectorySection on DatoCmsDirectorySection {
    id: originalId
    __typename
    heading
    people {
      ... on DatoCmsFacultyStaffMember {
        ...DirectoryFacultyStaffMember
      }
      ... on DatoCmsLeader {
        ...DirectoryLeader
      }
      ... on DatoCmsCoach {
        ...DirectoryCoach
      }
    }
  }
`
