import { css } from '@emotion/react'
import { graphql } from 'gatsby'
import { ComponentPropsWithoutRef } from 'react'

import { DatoStructuredText } from '@/components/DatoStructuredText'
import { DatoLink } from '@/features/dato-link'
import { mq } from '@/theme/mixins'
import { colors } from '@/theme/variables'

interface Props extends ComponentPropsWithoutRef<'div'> {
  data?: Queries.DescriptiveLinkFragment | null
}

export const DescriptiveLink = ({
  data,
  ...props
}: Props): JSX.Element => {
  const styles = {
    container: css`
      &:not(:nth-of-type(-n + 2)) {
        border-top: 2px solid rgba(0, 0, 0, 0.1);
        padding-top: 1em;
      }
      ${mq().s} {
        &:not(:first-child) {
          border-top: 2px solid rgba(0, 0, 0, 0.1);
          padding-top: 1em;
        }
      }
    `,
    title: css`
      font-size: var(--fs-36);
      margin: 0.5em 0 0.25em;
    `,
    body: css`
      font-weight: 350;
      p {
        margin: 0 0 0.5em;
      }
    `,
    link: css`
      margin-top: 0;
      color: ${colors.red};
      @media (hover: hover) {
        &:hover {
          color: ${colors.redDark};
        }
      }
    `,
  }
  return (
    <div
      css={styles.container}
      {...props}
    >
      <h3 css={styles.title}>{data?.heading}</h3>
      <div css={styles.body}>
        <DatoStructuredText data={data?.description} />
      </div>
      <DatoLink
        css={styles.link}
        data={data?.link?.[0]}
        showIcon
      />
    </div>
  )
}

export const DescriptiveLinkFragment = graphql`
  fragment DescriptiveLink on DatoCmsDescriptiveLink {
    heading
    description {
      value
    }
    link {
      ... on DatoCmsPageLink {
        ...PageLink
      }
      ... on DatoCmsExternalLink {
        ...ExternalLink
      }
      ... on DatoCmsTertiaryArticleLink {
        ...TertiaryArticleLink
      }
      ... on DatoCmsPdfLink {
        ...PdfLink
      }
      ... on DatoCmsFormLink {
        ...FormLink
      }
    }
  }
`
