import {
  Dispatch,
  ReactNode,
  SetStateAction,
  createContext,
  useContext,
  useState,
} from 'react'

interface IContext {
  searchIsOpen: boolean
  setSearchIsOpen: Dispatch<SetStateAction<boolean>>
  isSearching: boolean
  setIsSearching: Dispatch<SetStateAction<boolean>>
  isEmpty: boolean
  setIsEmpty: Dispatch<SetStateAction<boolean>>
}

const SearchContext = createContext<IContext | undefined>(undefined)

export const useSearchContext = () =>
  useContext(SearchContext) as IContext

export const SearchContextProvider = ({
  children,
}: {
  children: ReactNode
}) => {
  const [searchIsOpen, setSearchIsOpen] = useState(false)
  const [isSearching, setIsSearching] = useState(false)
  const [isEmpty, setIsEmpty] = useState(true)
  return (
    <SearchContext.Provider
      value={{
        searchIsOpen,
        setSearchIsOpen: value => setSearchIsOpen(value),
        isSearching,
        setIsSearching: value => setIsSearching(value),
        isEmpty,
        setIsEmpty: value => setIsEmpty(value),
      }}
    >
      {children}
    </SearchContext.Provider>
  )
}
