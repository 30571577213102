import { css } from '@emotion/react'
import { Link, graphql } from 'gatsby'
import { ComponentPropsWithoutRef } from 'react'

import { GatsbyImageFocused } from '@/components/GatsbyImageFocused'
import { absoluteFill, bezier } from '@/theme/mixins'
import { colors } from '@/theme/variables'

type GoFutherLink = NonNullable<
  Queries.GoFurtherSectionFragment['links']
>[number]

interface Props extends ComponentPropsWithoutRef<'a'> {
  data?: GoFutherLink | null
}

export const GoFurtherLink = ({
  data,
  ...props
}: Props): JSX.Element => {
  const getImage = () => {
    switch (data?.__typename) {
      case 'DatoCmsDivisionPage':
      case 'DatoCmsMissionPage':
        return data.images?.[0]
      case 'DatoCmsHistoryPage':
        return data.historySections?.[0]?.images?.[0]
      default:
        return data?.image
    }
  }

  const styles = {
    link: css`
      position: relative;
      display: grid;
      color: #fff;
      text-decoration: none;
      font-size: var(--fs-48);
    `,
    linkImage: css`
      grid-area: 1 / 1 / 2 / 2;
      ${absoluteFill}
      overflow: hidden;
      [data-gatsby-image-wrapper] {
        transition: transform 500ms ${bezier.easeOut};
      }
      &:after {
        content: '';
        ${absoluteFill}
        transition: opacity 300ms ease-out;
        background: ${colors.red};
        opacity: 0;
        mix-blend-mode: overlay;
      }
      @media (hover: hover) {
        a:hover & {
          [data-gatsby-image-wrapper] {
            transform: scale3d(1.05, 1.05, 1);
          }
          &:after {
            opacity: 1;
          }
        }
      }
    `,
    linkHeading: css`
      position: relative;
      grid-area: 1 / 1 / 2 / 2;
      align-self: center;
      justify-self: center;
      font-size: inherit;
      margin: 0.75em;
    `,
  }
  return (
    <Link
      css={styles.link}
      to={`/${data?.slug}/`}
      {...props}
    >
      <GatsbyImageFocused
        css={styles.linkImage}
        image={getImage()?.thumbnail}
        aspectRatio={7 / 4}
        data={getImage()}
      />
      <h3 css={styles.linkHeading}>{data?.heading}</h3>
    </Link>
  )
}

export const GoFurtherLinkFragment = graphql`
  fragment GoFurtherThumbnail on DatoCmsFileField {
    thumbnail: gatsbyImageData(
      width: 540
      sizes: "(max-width: 540px) 100vw, 33vw"
      imgixParams: {
        ar: "7:4"
        fit: "crop"
        crop: "focalpoint"
        sat: -100
        con: -50
        bri: -25
      }
    )
    ...ImageFocalData
  }
`
