import { css } from '@emotion/react'

import { mq } from '@/theme/mixins'
import { colors } from '@/theme/variables'

export const bodyGrid = css`
  position: relative;
  display: grid;
  grid-template-columns: 1fr 2fr;
  grid-column-gap: max(var(--gtr-54), 3em);
  padding: var(--row-72) var(--margin);
  ${mq().ms} {
    grid-template-columns: 1fr;
  }
`

export const bodyHeading = css`
  font-size: var(--fs-60);
  min-width: min-content;
  color: ${colors.gray10};
  border-top: 2px solid ${colors.red};
  margin: 2px 0 0.5em;
  padding-top: 0.375em;
`
