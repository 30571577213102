import { css } from '@emotion/react'
import { graphql } from 'gatsby'
import { ComponentPropsWithoutRef } from 'react'

import { BodyTextBlock } from '@/components/BodyTextBlock'
import { colors } from '@/theme/variables'

import { bodyGrid, bodyHeading } from '../../styles'
import { BoardGroup } from './BoardGroup'

interface Props extends ComponentPropsWithoutRef<'section'> {
  data?: Queries.BoardSectionFragment | null
}

export const BoardSection = ({
  data,
  ...props
}: Props): JSX.Element => {
  const styles = {
    section: css`
      ${bodyGrid}
      background: ${colors.gray20};
      color: #fff;
    `,
    heading: css`
      ${bodyHeading}
      color: #fff;
      border-color: ${colors.redLight};
    `,
    blocks: css`
      > *:last-child {
        margin-bottom: 1em;
      }
    `,
  }
  return (
    <section
      css={styles.section}
      {...props}
    >
      <h2 css={styles.heading}>{data?.heading}</h2>
      <div css={styles.blocks}>
        {data?.blocks?.map((block, i) => {
          switch (block?.__typename) {
            case 'DatoCmsBodyTextBlock':
              return (
                <BodyTextBlock
                  data={block}
                  key={i}
                />
              )
            case 'DatoCmsBoardGroup':
              return (
                <BoardGroup
                  data={block}
                  key={i}
                />
              )
          }
        })}
      </div>
    </section>
  )
}

export const BoardSectionFragment = graphql`
  fragment BoardSection on DatoCmsBoardSection {
    __typename
    id: originalId
    heading
    blocks {
      ... on DatoCmsBodyTextBlock {
        ...BodyTextBlock
      }
      ... on DatoCmsBoardGroup {
        ...BoardGroup
      }
    }
  }
`
