import { css } from '@emotion/react'
import { ComponentPropsWithoutRef } from 'react'

import { mq } from '@/theme/mixins'

interface Props extends ComponentPropsWithoutRef<'div'> {
  fill?: string
  flip?: boolean
}

export const Deco = ({
  fill = '#ffffff22',
  flip,
  ...props
}: Props): JSX.Element => {
  const style = css`
    position: relative;
    font-size: var(--fs-144);
    width: 1em;
    height: 13px;
    ${flip &&
    css`
      transform: scaleY(-1);
    `}
    ${mq().s} {
      height: 10px;
    }
    &:before {
      display: block;
      position: relative;
      content: '';
      height: 3px;
      width: 100%;
      border-radius: 1.5px;
      background: ${fill};
      transition: background 300ms ease;
      ${mq().s} {
        height: 2px;
      }
    }
    svg {
      width: 14px;
      height: 10px;
      position: absolute;
      top: 3px;
      left: 50%;
      transform: translateX(-50%);
      ${mq().s} {
        top: 2px;
        height: 8px;
      }
      path {
        transition: fill 300ms ease;
        fill: ${fill};
      }
    }
  `
  return (
    <div
      css={style}
      {...props}
    >
      <svg viewBox="0 0 14 10">
        <path d="M0.732422 0L5.48485 8.23145C6.15843 9.39811 7.84236 9.39811 8.51594 8.23145L13.2684 0H0.732422Z" />
      </svg>
    </div>
  )
}
