import { css } from '@emotion/react'
import { ComponentPropsWithoutRef } from 'react'

import { mq } from '@/theme/mixins'
import { colors } from '@/theme/variables'

import { Coach } from './Coach'

interface Props extends ComponentPropsWithoutRef<'section'> {
  data?: readonly (Queries.CoachFragment | null)[] | null
}

export const AthleticsTeamCoaches = ({
  data,
  ...props
}: Props): JSX.Element => {
  const styles = {
    section: css`
      margin: 0 var(--margin) var(--row-72);
    `,
    heading: css`
      font-size: var(--fs-48);
      color: ${colors.gray10};
      margin: 0 0 0.5em;
      &:after {
        content: '';
        display: block;
        width: 1em;
        margin-top: 0.5em;
        height: 2px;
        background: ${colors.red};
      }
    `,
    coaches: css`
      display: grid;
      grid-template-columns: 1fr 1fr;
      grid-gap: var(--gtr-36);
      ${mq().ms} {
        grid-template-columns: 1fr;
      }
    `,
  }
  return (
    <section
      css={styles.section}
      {...props}
    >
      <h2 css={styles.heading}>Coaches</h2>
      <div css={styles.coaches}>
        {data?.map((coach, i) => (
          <Coach
            data={coach}
            key={i}
          />
        ))}
      </div>
    </section>
  )
}
