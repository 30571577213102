import { buildClient } from '@datocms/cma-client-browser'
import { isEqual } from 'lodash'
import { useEffect, useRef, useState } from 'react'
import { UseSiteSearchData, useSiteSearch } from 'react-datocms'

const client = buildClient({
  apiToken: `${process.env.GATSBY_DATOCMS_SEARCH_KEY}`,
})

type Props = {
  resultsPerPage?: number
}

export const useDatoSearch = ({ resultsPerPage = 12 }: Props = {}) => {
  const {
    data,
    state: { locale, query, setQuery, setLocale },
    error,
  } = useSiteSearch({
    client,
    buildTriggerId: '27906',
    fuzzySearch: true,
    // optional: you can omit it you only have one locale, or you want to find results in every locale
    // initialState: { locale: 'en' },
    // optional: to configure how to present the part of page title/content that matches the query
    highlightMatch: (text, key) => <mark key={key}>{text}</mark>,
    // optional: defaults to 8 search results per page
    resultsPerPage,
  })
  useEffect(() => {
    if (error) {
      alert(error)
    }
  }, [error])
  const [results, setResults] = useState<UseSiteSearchData>()
  const prevResults = useRef(data)
  useEffect(() => {
    if (
      !isEqual(
        prevResults.current?.pageResults.map(result => result.id),
        data?.pageResults.map(result => result.id)
      )
    ) {
      setResults(() => {
        prevResults.current = data
        return data
      })
    }
  }, [data])

  return { results, query, setQuery, locale, setLocale }
}
