// Modified GatsbyImage component to handle Gatsby's incompatible type generation
import {
  GatsbyImage,
  GatsbyImageProps,
  IGatsbyImageData,
} from 'gatsby-plugin-image'
import { Fragment } from 'react'

interface Props extends Omit<GatsbyImageProps, 'image'> {
  image: IGatsbyImageData | Record<string, unknown> | null | undefined
}

export const DatoGatsbyImage = ({
  image,
  ...props
}: Props): JSX.Element => {
  if (image) {
    return (
      <GatsbyImage
        image={image as unknown as IGatsbyImageData}
        {...props}
      />
    )
  } else {
    return <Fragment />
  }
}
