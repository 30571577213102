import { graphql, useStaticQuery } from 'gatsby'
import { useMemo } from 'react'

export const useTertiaryArticleQuery = (slug?: string | null) => {
  const { allDatoCmsTertiaryArticle } =
    useStaticQuery<Queries.useTertiaryArticleQueryQuery>(graphql`
      query useTertiaryArticleQuery {
        allDatoCmsTertiaryArticle {
          nodes {
            ...TertiaryArticle
          }
        }
      }
    `)

  const article = useMemo(
    () =>
      allDatoCmsTertiaryArticle?.nodes?.find(
        article => article.slug === slug
      ),
    [slug, allDatoCmsTertiaryArticle]
  )

  return article
}
