import { css } from '@emotion/react'
import { Record } from 'datocms-structured-text-utils'
import { graphql } from 'gatsby'
import { kebabCase } from 'lodash'
import {
  ChangeEvent,
  useCallback,
  useEffect,
  useId,
  useState,
} from 'react'

import { useElementHeight } from '@/hooks/useElementRect'

import { fieldStyles } from './styles/fieldStyles'

export interface ITextField extends Record {
  __typename: 'DatoCmsTextField'
  label: string
  fieldType: 'text' | 'email' | 'tel' | 'zip'
  required: boolean
  width: 'Full' | 'Half' | 'Third' | 'Quarter'
}

type FieldProps = {
  data?: Queries.FormTextFieldFragment | null
  sectionName?: string | null
  onChange: (name: string, value: string) => void
}

export const FormTextField = ({
  data,
  sectionName,
  onChange,
}: FieldProps): JSX.Element => {
  const name = `${sectionName ? kebabCase(sectionName) + '-' : ''}${data?.label ? kebabCase(data?.label) : ''}`
  const [shrink, setShrink] = useState(false)
  const [value, setValue] = useState('')
  const handleFocus = () => {
    if (!shrink) {
      setShrink(true)
    }
  }
  const handleBlur = () => {
    if (value.length > 0) {
      setShrink(true)
    } else {
      setShrink(false)
    }
  }
  useEffect(() => {
    if (value.length > 0) {
      setShrink(true)
    }
  }, [value])

  const handleChange = useCallback(
    (e: ChangeEvent<HTMLInputElement>) => {
      const getFormattedPhoneNum = (e: any) => {
        let output = ''
        const inputType = e.nativeEvent.inputType
        const input = e.target.value
        input.replace(
          /^\D*(\d{0,3})\D*(\d{0,3})\D*(\d{0,4})/,
          (_: string, g1: string, g2: string, g3: string) => {
            if (g1.length) {
              output += '(' + g1
              if (g1.length === 3) {
                output += ')'
                if (g2.length) {
                  output += ' ' + g2
                  if (g2.length === 3) {
                    output += '-'
                    if (g3.length) {
                      output += g3
                    }
                  }
                }
              }
            }
            if (inputType === 'deleteContentBackward') {
              if (input.length === 4 || input.length === 9) {
                output = output.slice(0, -2)
              }
              if (input.length === 6 || input.length === 10) {
                output = output.slice(0, -1)
              }
            }
          }
        )
        return output
      }

      const val =
        data?.fieldType === 'tel'
          ? getFormattedPhoneNum(e)
          : e.target.value
      setValue(val)
      onChange(name, val)
    },
    [onChange, name, data?.fieldType]
  )

  const uniqueId = useId()

  const [labelRef, setLabelRef] = useState<HTMLElement | null>(null)
  const labelHeight = useElementHeight(labelRef)

  const styles = {
    input: css`
      min-height: max(3.333em, 2em + ${labelHeight}px);
      transition: min-height 150ms ease;
    `,
  }

  return (
    <div css={() => fieldStyles.container(data?.width)}>
      <label
        htmlFor={name + uniqueId}
        css={[
          fieldStyles.label,
          shrink && fieldStyles.shrink,
          data?.required && fieldStyles.required,
        ]}
        ref={node => setLabelRef(node)}
      >
        {data?.label}
      </label>
      <div css={[fieldStyles.inputBase]}>
        <input
          css={[fieldStyles.input, styles.input]}
          value={value}
          name={name}
          id={name + uniqueId}
          type={data?.fieldType || undefined}
          required={data?.required || undefined}
          onChange={handleChange}
          onFocus={handleFocus}
          onBlur={handleBlur}
          inputMode={data?.fieldType === 'zip' ? 'numeric' : undefined}
        />
      </div>
    </div>
  )
}

export const FormTextFieldFragment = graphql`
  fragment FormTextField on DatoCmsFormTextField {
    __typename
    id: originalId
    label
    fieldType
    width
    required
  }
`
