import { graphql } from 'gatsby'
import { ComponentPropsWithoutRef } from 'react'

import { useLocalFileUrl } from '@/hooks/useLocalFileUrl'

import { DatoLinkIcon } from './DatoLinkIcon'

interface Props extends ComponentPropsWithoutRef<'a'> {
  data?: Queries.PdfLinkFragment | null
  showIcon?: boolean
}

export const PdfLink = ({
  data,
  showIcon,
  ...props
}: Props): JSX.Element => {
  const url = useLocalFileUrl(data?.pdf?.localFileId)

  return (
    <a
      href={url ? url + '#page=1&view=Fit' : undefined}
      target="_blank"
      rel="noreferrer"
      {...props}
    >
      <span>{data?.linkText}</span>
      {showIcon && <DatoLinkIcon iconType="DOWNLOAD" />}
    </a>
  )
}

export const PdfLinkFragment = graphql`
  fragment PdfLink on DatoCmsPdfLink {
    id: originalId
    __typename
    pdf {
      localFileId
    }
    linkText
  }
`
