import { graphql } from 'gatsby'
import { ComponentPropsWithoutRef } from 'react'

import {
  ArticleBody,
  ArticleContainer,
  ArticleHeading,
  ArticleLayoutOptions,
} from '@/features/article-sections'

interface Props extends ComponentPropsWithoutRef<'article'> {
  data?: Queries.BrearleyByteFragment | null
  layout: ArticleLayoutOptions
  disableLightboxLinks?: boolean
}

export const BrearleyByte = ({
  data,
  layout,
  disableLightboxLinks,
  ...props
}: Props): JSX.Element => {
  return (
    <ArticleContainer
      layout={layout}
      {...props}
    >
      <ArticleHeading
        heading={data?.heading}
        subheading={data?.subheading}
      />
      <ArticleBody
        data={data?.blocks}
        disableLightboxLinks={disableLightboxLinks}
      />
    </ArticleContainer>
  )
}

export const BrearleyByteFragment = graphql`
  fragment BrearleyByte on DatoCmsBrearleyByte {
    __typename
    id: originalId
    heading
    subheading
    blocks {
      ... on DatoCmsBodySection {
        ...BodySection
      }
      ... on DatoCmsBodyTextBlock {
        ...BodyTextBlock
      }
      ... on DatoCmsMediaCarousel {
        ...MediaCarousel
      }
    }
    slug
    seoMetaTags {
      tags
    }
  }
`
