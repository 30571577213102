import Markdown from 'markdown-to-jsx'
import { ComponentPropsWithoutRef, ElementType } from 'react'

type Props = ComponentPropsWithoutRef<'div'> & {
  wrapper?: ElementType
}

export const MarkdownHeading = ({
  children,
  wrapper = 'h2',
  ...props
}: Props): JSX.Element => {
  const Wrapper = wrapper
  return (
    <Wrapper {...props}>
      <Markdown
        options={{
          overrides: {
            p: ({ children }) => children,
          },
        }}
      >
        {children?.toString().replace(/\\n/, '<br />') || ''}
      </Markdown>
    </Wrapper>
  )
}
