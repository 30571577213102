import { css, keyframes } from '@emotion/react'
import { ComponentPropsWithoutRef } from 'react'

interface Props extends ComponentPropsWithoutRef<'h2'> {
  isSearching: boolean
  duration?: number
}

export const SearchingInterstitial = ({
  isSearching,
  duration = 1000,
  ...props
}: Props): JSX.Element => {
  const animation = keyframes`
    0%, 25%, 75%, 100% {
      transform: translate3d(0, 0, 0);
    }
    50% {
      transform: translate3d(0, -0.125em, 0);
    }
  `
  const styles = {
    h2: css`
      opacity: 0;
      transition: opacity 300ms ease 150ms;
      pointer-events: none;
      ${isSearching &&
      css`
        opacity: 1;
        transition-delay: 0ms;
      `}
    `,
    dot: css`
      display: inline-block;
      animation: ${animation} ${duration}ms ease-in-out forwards
        infinite;
      &:nth-last-of-type(2) {
        animation-delay: ${(-1 * duration) / 3 / 3}ms;
      }
      &:nth-last-of-type(3) {
        animation-delay: ${(-1 * (duration * 2)) / 3 / 3}ms;
      }
    `,
  }
  return (
    <h2
      css={styles.h2}
      {...props}
    >
      <span>Searching</span>
      <span css={styles.dot}>.</span>
      <span css={styles.dot}>.</span>
      <span css={styles.dot}>.</span>
    </h2>
  )
}
