import { css } from '@emotion/react'
import { ComponentPropsWithoutRef } from 'react'

interface Props extends ComponentPropsWithoutRef<'svg'> {
  fill?: string
}

export const BrearleyLogoStacked = ({
  fill,
  ...props
}: Props): JSX.Element => {
  const style = css`
    height: 3em;
    width: auto;
    path {
      fill: currentColor;
      fill: ${fill};
    }
  `
  return (
    <svg
      viewBox="0 0 293 150"
      css={style}
      aria-label="The Brearley School"
      {...props}
    >
      <path d="M42.5297 21.2202C42.5297 25.5773 42.2312 37.4596 41.4619 38.5954C40.8119 39.5452 38.265 39.8573 37.7344 39.9237L38.1456 41.0595C40.9843 40.8071 41.7537 40.7407 43.5312 40.7407C44.5991 40.7407 45.9587 40.8071 47.3184 40.9333C48.7378 40.9997 50.0975 41.1259 51.1056 41.1857L51.1653 40.1761C47.7893 39.419 47.5174 38.9872 47.2189 37.7851C46.9205 36.9017 46.8807 34.4442 46.8807 32.2325C46.8807 28.8185 46.8939 22.7811 46.947 21.5789C55.1714 21.3863 66.4998 21.4195 72.5221 21.5789C72.6415 24.6142 72.6084 29.3034 72.5221 31.6413C72.2635 38.529 72.2237 39.4854 68.3768 39.9237L68.4962 41.0595C71.6334 40.9333 73.9415 40.6809 75.0027 40.6809C76.7803 40.6809 79.0884 40.7407 80.7996 41.0595L80.8593 40.1761C77.1318 39.7311 76.6012 39.2264 76.7803 29.0576L77.019 13.3231C77.1981 3.78534 77.1981 2.45696 80.8062 1.75956L80.6868 0.876196C80.3353 0.876196 79.0884 0.935972 77.7884 1.00239C76.5481 1.06217 75.2481 1.12859 74.7706 1.12859C73.4109 1.12859 72.1109 1.12859 70.9834 1.00239C69.8625 0.876196 68.9737 0.809777 68.3237 0.75L68.2044 1.75956C71.1625 2.45696 71.3747 2.42375 72.1972 3.71227C72.4558 4.67535 72.5221 5.25319 72.5221 12.6456V19.2675C71.3416 19.5199 69.3584 19.2476 59.2968 19.4269L46.947 19.2675C46.8873 17.5008 46.8807 16.0595 46.8807 14.4787C46.8807 12.267 46.9802 10.0951 47.2189 7.94315C47.6898 2.69607 47.6766 2.70935 50.2169 2.32412L51.7556 2.07173L51.6959 0.995749L47.4975 1.05553C45.4878 1.12194 42.8812 0.869554 41.4022 0.869554C40.5134 0.869554 39.3925 0.92933 38.5038 0.995749L38.4441 2.00531C42.1119 2.19793 42.5828 3.14107 42.5828 11.1645L42.5231 21.2136L42.5297 21.2202Z" />
      <path d="M110.598 15.1801H109.782C108.654 19.0722 108.343 19.0722 106.081 19.3246C104.264 19.5106 100.377 19.577 97.18 19.577C95.1769 19.577 93.4193 19.577 92.6698 19.5172C92.6101 18.4479 92.6101 14.1838 92.6101 11.547C92.6101 7.90721 92.9219 5.27039 93.1739 3.01215C95.6213 2.69999 98.3738 2.38118 102.572 2.38118C105.457 2.38118 111.659 2.44095 111.659 5.83495C111.659 6.14712 111.407 7.78102 111.407 8.40535L112.223 8.65774C112.972 7.5884 113.416 5.018 113.728 0.6875C110.405 0.813696 105.895 0.939891 103.766 0.939891H84.147L84.0873 1.75684C87.7883 2.50737 88.0337 2.50737 88.226 10.9226L88.4117 20.653C88.478 22.7252 88.1597 33.3921 87.7219 36.4673C87.3439 39.0377 87.2179 39.1041 83.6495 39.8546L83.5898 40.924C87.5362 40.738 94.2417 40.4856 100.072 40.4856C100.131 40.4856 102.957 40.6118 106.028 40.6118C109.099 40.6118 112.481 40.6716 113.675 40.7978C114.988 38.0347 115.492 36.2149 116.242 31.5058H115.3C113.357 37.0318 110.724 38.9779 101.073 38.9779C95.807 38.9779 94.3014 38.3535 93.1142 38.0347C92.4907 34.5212 92.4907 28.1782 92.5504 25.734L92.6764 21.4035C93.8703 21.2175 95.1239 21.0913 98.1947 21.0913C108.223 21.0913 109.351 21.3437 109.536 26.7369H110.604V15.1867L110.598 15.1801Z" />
      <path d="M30.2758 84.9494C30.415 92.6008 22.1973 93.5838 17.1566 93.617C10.126 93.6701 10.126 93.7698 10.126 86.5501V74.4087C11.6316 74.2228 14.9015 74.1563 15.2 74.1563C25.5865 74.1563 30.1365 77.2913 30.2758 84.9427M12.6398 72.901C11.8107 72.901 10.8755 72.901 10.126 72.9674V69.2081C10.126 67.2621 10.0067 57.5185 10.8357 56.9539C11.2867 56.5155 13.9928 56.4491 14.8219 56.4491C20.0152 56.4491 25.6595 59.4247 25.6595 65.0703C25.6595 72.6619 17.004 72.828 12.6398 72.8944M21.1427 73.2065V73.0804C27.762 71.6391 30.4747 68.3713 30.4747 64.4194C30.4747 58.3288 24.4524 54.9414 17.4617 54.9414C16.7851 54.9414 14.603 55.0676 12.4939 55.1938C10.3118 55.32 8.2026 55.4462 7.38016 55.4462C6.39854 55.4462 5.12509 55.3864 3.91797 55.3864C2.71084 55.3266 1.51035 55.2602 0.455771 55.2004V56.3893C2.25983 56.6417 3.91797 56.8941 4.74704 58.2092C5.72202 59.6505 6.02712 64.3596 6.02712 67.8731V70.8885C6.02712 71.8915 5.95416 88.277 5.12509 91.5381C4.67408 93.544 2.56492 93.7963 0.382812 93.989V95.1181C4.14347 94.9321 5.27101 94.8657 7.60567 94.8657C8.28219 94.8657 10.3118 94.9321 12.2684 94.9321C14.2979 94.9919 16.3341 95.0583 17.0836 95.0583C20.4662 95.0583 35.217 95.0583 35.217 84.4512C35.217 80.1805 32.3584 74.9733 21.1494 73.2132" />
      <path d="M101.261 69.5797H100.445C99.3175 73.4718 99.0058 73.4718 96.7441 73.7242C94.9268 73.9102 91.0401 73.9766 87.8432 73.9766C85.8402 73.9766 84.0825 73.9766 83.3331 73.9168C83.2667 72.8475 83.2667 68.5834 83.2667 65.9466C83.2667 62.3068 83.5785 59.67 83.8305 57.4118C86.2779 57.0996 89.0304 56.7874 93.2288 56.7874C96.114 56.7874 102.315 56.8472 102.315 60.2412C102.315 60.5534 102.063 62.1873 102.063 62.8116L102.879 63.064C103.629 61.9947 104.066 59.4242 104.385 55.0938C101.062 55.2199 96.5518 55.3461 94.4161 55.3461H74.797L74.7306 56.1631C78.4316 56.9136 78.677 56.9136 78.8694 65.3289L79.0551 75.0592C79.1148 77.1315 78.803 87.7983 78.3653 90.8735C77.9872 93.4439 77.8612 93.5103 74.2929 94.2609L74.2266 95.3302C78.1729 95.1442 84.8851 94.8918 90.7085 94.8918C90.7682 94.8918 93.5936 95.018 96.6645 95.018C99.7354 95.018 103.118 95.0778 104.312 95.204C105.632 92.441 106.129 90.6211 106.879 85.912H105.937C103.993 91.4381 101.36 93.3841 91.71 93.3841C86.4437 93.3841 84.9381 92.7532 83.7509 92.441C83.1275 88.9274 83.1275 82.5845 83.1871 80.1402L83.3132 75.8097C84.5004 75.6238 85.7606 75.4976 88.8315 75.4976C98.8599 75.4976 99.9874 75.75 100.173 81.1498H101.241V69.5996L101.261 69.5797Z" />
      <path d="M131.489 79.119C130.488 79.1788 127.477 79.2452 126.913 79.2452L124.094 79.3116C124.028 79.3116 121.083 79.3116 118.768 78.933C119.02 78.4282 119.08 78.2423 119.71 76.5486L123.159 67.6352C123.47 66.9444 124.353 64.6862 125.036 63.1121C125.54 64.1814 127.543 68.6979 127.921 69.7672L130.116 75.4128C130.428 76.0438 131.244 78.4282 131.496 79.119M144.973 95.1259V94.0565C143.72 93.9304 142.406 93.678 141.717 92.675C140.277 90.729 137.704 83.8281 135.761 78.7404L129.307 61.4782C128.684 59.7181 128.18 58.403 127.238 54.9492H126.236C124.983 59.4657 122.913 64.1748 121.096 68.3791L116.208 80.1153C115.266 82.6923 111.505 91.5991 110.006 92.8544C109.88 92.9806 108.938 93.8573 107.188 94.2957V95.1724C108.693 95.0462 110.57 94.92 112.765 94.92C115.777 94.92 117.342 95.1724 118.848 95.3584V94.3554C114.523 93.7245 113.959 92.6618 113.959 91.4064C113.959 90.5895 114.271 89.7725 115.465 86.9497L117.72 81.3042C119.478 80.992 122.23 80.8658 124.114 80.9256H126.555C128.312 80.9256 130.567 80.9256 132.318 81.178L134.573 87.3283C134.825 87.8929 135.953 90.9681 135.953 92.2234C135.953 93.6049 134.195 93.9835 132.882 94.2292V95.2322C133.884 95.1657 136.457 94.92 140.151 94.92C142.718 94.92 143.726 94.9864 144.98 95.106" />
      <path d="M191.354 82.5199C191.294 92.6886 191.168 93.1867 186.844 94.0701L186.903 95.0133H208.4C209.215 95.0133 211.033 95.073 212.034 95.073C213.036 95.073 214.104 95.1328 216.233 95.3852C217.612 91.9978 218.362 88.3581 218.554 87.0364L217.553 86.9102C216.863 89.547 215.549 90.5499 214.86 90.9883C213.48 91.7388 209.786 93.1867 203.2 93.1867C197.118 93.1867 196.806 92.8746 196.302 92.4362C195.672 91.8052 195.486 90.0518 195.486 85.2165C195.486 80.3812 195.612 73.9851 195.738 68.9638C195.864 63.8762 195.924 59.7383 196.368 58.6689C197.058 57.035 199.061 56.9753 201.004 56.7893L201.064 55.5938C199.432 55.7199 196.992 55.7199 194.292 55.7199C193.662 55.7199 193.039 55.6602 187.275 55.5938V56.6631C191.473 56.7893 191.473 57.6062 191.414 69.0303L191.354 82.5265V82.5199Z" />
      <path d="M246.358 69.7113H245.542C244.415 73.6034 244.103 73.6034 241.841 73.8558C240.024 74.0418 236.137 74.1082 232.941 74.1082C230.937 74.1082 229.18 74.1082 228.43 74.0485C228.371 72.9791 228.371 68.715 228.371 66.0782C228.371 62.4385 228.682 59.8016 228.934 57.5434C231.382 57.2312 234.134 56.9124 238.333 56.9124C241.218 56.9124 247.419 56.9788 247.419 60.3662C247.419 60.6784 247.167 62.3123 247.167 62.9366L247.983 63.189C248.733 62.1197 249.177 59.5492 249.489 55.2188C246.166 55.3449 241.656 55.4711 239.52 55.4711H219.901L219.841 56.2881C223.542 57.0386 223.788 57.0386 223.98 65.4539L224.166 75.1842C224.225 77.2565 223.914 87.9233 223.476 90.9985C223.098 93.5689 222.972 93.6353 219.403 94.3859L219.344 95.4552C223.29 95.2692 230.002 95.0168 235.826 95.0168C235.892 95.0168 238.711 95.143 241.782 95.143C244.853 95.143 248.235 95.2028 249.429 95.329C250.742 92.566 251.246 90.7461 251.996 86.037H251.054C249.111 91.5631 246.478 93.5091 236.827 93.5091C231.561 93.5091 230.055 92.8782 228.868 92.566C228.238 89.0524 228.238 82.7095 228.304 80.2652L228.43 75.9347C229.624 75.7488 230.878 75.6226 233.949 75.6226C243.977 75.6226 245.105 75.875 245.297 81.2682H246.365V69.718L246.358 69.7113Z" />
      <path d="M277.833 70.5235C275.89 73.4127 275.638 73.7249 275.074 74.4754C273.568 72.7153 263.288 59.4117 263.288 57.904C263.288 56.9608 264.667 56.901 266.989 56.7084L267.115 55.5195H265.676C263.606 55.5195 261.663 55.6457 259.912 55.6457C259.222 55.6457 256.025 55.5195 255.402 55.5195H252.391L252.576 56.5225C256.337 56.7084 256.589 57.0272 262.731 64.6787C264.17 66.4388 266.803 69.9523 268.999 73.0873L271.506 76.7934C271.565 77.4842 271.565 78.9255 271.565 80.5594C271.565 92.1694 271.38 92.362 270.564 93.1125C269.622 94.1154 267.745 94.3081 266.299 94.3678V95.2446C267.175 95.2446 268.494 95.1184 270.06 95.0586C271.061 95.0586 272.627 94.7464 273.761 94.7464C276.082 94.7464 278.835 95.125 281.282 95.2512L281.342 94.4342C280.652 94.2483 279.213 93.8697 278.085 93.6173C275.956 93.1789 275.956 92.4882 275.89 87.6529L275.764 76.4813C277.959 73.1537 280.148 69.7663 282.343 66.379C287.981 57.8442 289.175 57.087 292.935 56.901L292.995 55.6457C292.053 55.6457 287.855 55.7719 286.979 55.7719C286.416 55.7719 283.597 55.6457 282.967 55.6457L280.4 55.5195L280.274 56.7084C281.527 56.6486 284.413 56.5822 284.413 58.7143C284.413 60.408 281.468 65.0506 280.148 66.9967L277.826 70.5102L277.833 70.5235Z" />
      <path d="M26.1789 118.586C26.1789 117.956 25.9269 115.638 25.8672 114.568C25.8075 113.001 26.1192 111.055 26.3049 109.733C26.1192 109.673 25.8672 109.607 25.6748 109.607C25.0514 109.985 24.4213 110.357 23.6055 110.357C23.4198 110.357 23.2938 110.357 22.0402 110.105C18.903 109.414 16.8403 109.414 15.7127 109.414C6.87816 109.414 2.61343 114.435 2.61343 120.207C2.61343 126.357 7.37561 128.928 13.6434 131.253C19.0953 133.325 23.5458 135.018 23.5458 141.109C23.5458 148.076 16.9663 148.076 15.7127 148.076C11.1363 148.076 7.24959 146.316 5.49859 145.38C3.1175 144.065 2.67975 142.617 1.48589 138.851C1.04814 138.917 0.922124 138.977 0.484375 139.103C1.30018 141.8 2.242 145.885 2.17568 149.332L3.42923 149.391C3.36954 149.013 3.24352 148.322 4.05269 148.322C4.2384 148.322 4.49044 148.382 5.05421 148.508C9.69037 149.763 13.2653 149.763 14.0148 149.763C23.1677 149.763 27.6182 144.995 27.6182 139.342C27.6182 131.751 20.7203 129.114 15.8321 127.228C10.8179 125.281 6.43378 123.648 6.43378 118.314C6.43378 117.119 6.74551 110.656 14.7709 110.656C17.9678 110.656 24.733 111.785 25.2371 118.626L26.1789 118.567V118.586Z" />
      <path d="M64.1253 143.124C64.2513 142.686 64.689 141.178 64.689 140.673C64.689 140.235 64.3773 140.109 64.1253 140.109C63.8135 140.109 63.6875 140.295 63.3095 140.985C61.2401 144.691 58.9187 148.331 52.0275 148.331C47.325 148.331 35.1675 144.253 35.1675 129.123C35.1675 117.32 42.1251 110.605 50.8336 110.605C55.6621 110.605 60.61 113.176 61.6115 114.75C61.8636 115.129 62.1156 115.693 62.1156 117.387C62.1156 118.204 62.2416 118.582 63.0574 118.702C63.3095 116.756 63.6875 114.624 64.1849 112.864C64.311 112.737 64.6227 111.17 64.6227 111.104C64.6227 111.104 64.4967 110.851 64.2446 110.851C64.0589 110.851 63.3028 111.037 63.1171 111.037C61.9896 111.037 60.9217 110.659 60.358 110.473C59.2304 110.034 56.0932 108.965 52.1469 108.965C42.0587 108.965 29.9609 114.863 29.9609 129.176C29.9609 142.606 40.8052 149.699 50.9596 149.699C55.6621 149.699 59.6748 148.57 62.8054 147.627L64.1186 143.111L64.1253 143.124Z" />
      <path d="M148.184 131.797C148.184 143.035 140.723 147.804 134.209 147.804C124.493 147.804 116.03 138.14 116.03 127.155C116.03 116.926 123.113 111.088 130.694 111.088C140.285 111.088 148.184 120.313 148.184 131.797ZM152.82 129.346C152.82 116.919 142.48 108.949 132.638 108.949C121.482 108.949 111.453 118.049 111.453 129.473C111.453 138.386 118.411 149.996 131.57 149.996C144.046 149.996 152.82 140.458 152.82 129.346Z" />
      <path d="M156.602 129.473C156.602 118.049 166.63 108.949 177.786 108.949C187.629 108.949 197.969 116.919 197.969 129.346C197.969 140.458 189.194 149.996 176.718 149.996C163.559 149.996 156.602 138.386 156.602 129.473ZM161.178 127.148C161.178 138.134 169.641 147.798 179.358 147.798C185.878 147.798 193.333 143.029 193.333 131.791C193.333 120.553 185.433 111.081 175.843 111.081C168.255 111.081 161.178 116.919 161.178 127.148Z" />
      <path d="M202.647 136.871C202.581 147.04 202.461 147.538 198.137 148.422L198.196 149.365H219.693C220.508 149.365 222.326 149.425 223.327 149.425C224.329 149.425 225.397 149.484 227.526 149.737C228.905 146.349 229.655 142.71 229.847 141.388L228.845 141.262C228.156 143.899 226.842 144.901 226.153 145.34C224.773 146.09 221.079 147.538 214.493 147.538C208.411 147.538 208.099 147.226 207.601 146.788C206.971 146.157 206.786 144.403 206.786 139.568C206.786 134.733 206.912 128.33 207.038 123.315C207.164 118.228 207.223 114.09 207.668 113.021C208.357 111.387 210.361 111.327 212.304 111.141L212.364 109.945C210.732 110.072 208.291 110.072 205.592 110.072C204.968 110.072 204.338 110.012 198.574 109.945V111.015C202.773 111.141 202.773 111.958 202.713 123.382L202.653 136.878L202.647 136.871Z" />
      <path d="M35.7031 1.12912C35.3648 0.876727 35.2521 0.81695 34.9735 0.378587C34.4694 1.00956 34.297 1.25531 32.6123 1.25531C31.7103 1.25531 27.041 1.38151 26.0262 1.38151H12.1243C7.68051 1.38151 5.42543 1.31509 4.13209 1.12912C2.83874 0.943146 2.22854 0.630978 1.61171 0C0.955089 3.44049 1.06784 4.13788 0 7.21307L0.842335 7.45882C1.96987 4.44341 2.16885 3.25452 9.98864 3.25452C12.5753 3.25452 13.9814 3.38071 15.1687 3.50691C15.7656 5.22051 15.8319 7.77763 15.8452 16.0268C15.8253 17.1892 15.8054 18.4644 15.8054 19.8858C15.8054 26.1025 15.6197 32.1865 15.6197 32.8773C15.56 33.382 15.3676 36.1384 15.3676 36.331C15.3676 38.6557 15.1819 39.0276 10.8575 40.0372C9.85599 40.2896 9.54425 40.3494 8.97385 40.542C9.22589 41.2327 9.4514 41.3988 10.1412 41.3988C10.5192 41.3988 10.6055 41.4785 12.1707 41.2261C15.7457 40.6615 17.3707 40.6615 18.6906 40.6615C19.2543 40.6615 20.6339 40.7213 22.0135 40.8475C23.4527 40.9073 24.8323 41.0335 25.2701 41.0335C25.6481 41.0335 26.0195 41.0335 26.4639 40.9737C26.5236 40.8475 26.5236 40.2829 26.5236 40.2232C26.5236 39.9708 26.3976 39.9708 25.144 39.8446C21.8852 39.4239 20.2558 35.7001 20.2558 28.673C20.2558 26.6007 20.3155 16.432 20.3819 14.0476C20.5079 9.65064 20.6936 6.64187 20.7599 4.56961C20.8063 3.73273 21.1048 3.36079 22.7232 3.20802C23.2339 3.20138 23.7578 3.19474 24.5073 3.19474C30.921 3.19474 32.3868 4.07147 33.2291 4.82864C33.3949 5.5991 33.448 6.34299 33.322 7.56509L34.5888 7.37912C34.8143 5.99761 35.0862 2.94235 35.7031 1.12912Z" />
      <path d="M72.0778 130.013C72.0778 134.344 71.7793 146.146 71.0166 147.275C70.3666 148.219 67.8197 148.531 67.2891 148.59L67.7003 149.72C70.539 149.467 71.3084 149.407 73.0859 149.407C74.1538 149.407 75.5134 149.467 76.8731 149.593C78.2925 149.653 79.6522 149.779 80.6603 149.846L80.72 148.843C77.3507 148.092 77.0721 147.661 76.7736 146.465C76.4752 145.588 76.4354 143.144 76.4354 140.946C76.4354 137.558 76.4486 131.554 76.5017 130.359C84.7261 130.173 96.0545 130.199 102.077 130.359C102.196 133.374 102.163 138.03 102.077 140.355C101.818 147.196 101.778 148.145 97.9315 148.584L98.0509 149.713C101.188 149.587 103.496 149.334 104.557 149.334C106.335 149.334 108.636 149.394 110.354 149.713L110.414 148.836C106.686 148.398 106.156 147.893 106.328 137.791L106.567 122.162C106.746 112.685 106.746 111.369 110.354 110.679L110.235 109.802C109.877 109.802 108.636 109.862 107.336 109.928C106.096 109.988 104.79 110.054 104.319 110.054C102.959 110.054 101.659 110.054 100.531 109.928C99.4039 109.802 98.5218 109.742 97.8718 109.676L97.7524 110.679C100.711 111.369 100.923 111.336 101.745 112.618C102.004 113.575 102.077 114.146 102.077 121.492V128.067C100.896 128.32 98.9131 128.047 88.8515 128.227L76.5017 128.067C76.442 126.307 76.4354 124.879 76.4354 123.312C76.4354 121.113 76.5349 118.954 76.7736 116.816C77.2445 111.609 77.2313 111.615 79.7715 111.237L81.3103 110.984L81.2506 109.915L77.0522 109.975C75.0425 110.034 72.7609 109.975 71.1757 109.928C70.287 109.902 68.9472 109.848 68.0584 109.915L67.9987 110.918C71.6666 111.104 72.1375 112.047 72.1375 120.017L72.0778 130V130.013Z" />
      <path d="M49.2946 74.3302C46.4095 74.3302 45.0962 74.0181 44.3401 73.8255C44.3401 68.4256 44.3401 59.0141 45.0299 57.5064C45.4676 56.3773 48.7309 56.0651 50.0441 56.0651C57.1277 56.0651 60.0062 62.4014 60.0062 65.9814C60.0062 74.3302 51.3574 74.3302 49.288 74.3302M72.8933 94.8935C72.8933 94.4551 72.7076 94.3289 72.3295 94.2625C66.4995 93.8507 61.0077 86.0731 55.5889 77.9036C55.4894 77.7508 55.443 77.6312 55.3369 77.4718L54.3221 75.9442L56.1262 75.3996C62.0822 73.2011 64.649 69.123 64.649 65.2309C64.649 60.0834 59.9465 54.75 51.0456 54.75C49.288 54.75 41.4549 55.5005 39.8897 55.5005C38.7621 55.5005 37.3825 55.5005 35.7509 55.4408C35.6846 55.7529 35.6846 55.9455 35.6249 56.4437C39.8233 57.0082 40.0754 57.0747 40.009 69.1828V82.7388C39.9493 92.529 39.2596 93.1599 36.8121 93.8507C35.6846 94.1629 35.5586 94.2293 35.5586 94.6676C35.5586 94.9798 35.8106 95.106 36.1821 95.106C36.4938 95.106 37.4356 94.9798 38.6892 94.92C39.883 94.7938 41.382 94.7341 42.8279 94.7341C43.332 94.7341 44.7049 94.8602 46.0248 94.9864C47.2783 95.1126 48.3462 95.2388 48.5916 95.2388C48.9696 95.2388 49.0293 94.8603 49.0293 94.6079C49.0293 94.3555 48.9033 94.3555 48.3395 94.1695C45.5804 93.419 45.3284 93.2928 44.8309 92.2234C44.1411 90.5895 44.0814 85.0037 44.0814 82.9315L44.1478 75.5855C45.5406 75.7782 46.9135 75.7715 48.2931 75.818C48.2069 75.8977 48.2533 75.9641 48.6844 75.9641L52.1931 80.9854C52.2594 81.085 52.3191 81.1382 52.3854 81.2311C57.4925 88.4243 63.4352 95.458 71.3214 95.458C72.8866 95.458 72.8866 95.2056 72.8866 94.8935" />
      <path d="M160.942 74.2834C158.056 74.2834 156.743 73.9712 155.994 73.7786C155.994 68.3787 155.994 58.9672 156.683 57.4595C157.121 56.3304 160.384 56.0182 161.698 56.0182C168.781 56.0182 171.666 62.3546 171.666 65.9345C171.666 74.2834 163.018 74.2834 160.948 74.2834M184.553 94.8466C184.553 94.4082 184.368 94.282 183.99 94.2156C178.16 93.8038 172.668 86.0262 167.249 77.8567C167.15 77.7039 167.103 77.5844 166.997 77.425L165.982 75.8973L167.786 75.3527C173.742 73.1542 176.309 69.0761 176.309 65.184C176.309 60.0365 171.607 54.7031 162.706 54.7031C160.948 54.7031 153.115 55.4537 151.55 55.4537C150.422 55.4537 149.043 55.4537 147.411 55.3939C147.351 55.706 147.351 55.8987 147.285 56.3968C151.483 56.9614 151.736 57.0278 151.669 69.1359V82.692C151.609 92.4821 150.92 93.1131 148.472 93.8038C147.345 94.116 147.219 94.1824 147.219 94.6208C147.219 94.9329 147.471 95.0591 147.849 95.0591C148.161 95.0591 149.102 94.9329 150.356 94.8732C151.55 94.747 153.049 94.6872 154.495 94.6872C154.999 94.6872 156.378 94.8134 157.692 94.9396C158.945 95.0658 160.013 95.192 160.258 95.192C160.636 95.192 160.696 94.8134 160.696 94.5676C160.696 94.3219 160.57 94.3152 160.006 94.1293C157.247 93.3787 156.995 93.2525 156.498 92.1832C155.808 90.5493 155.748 84.9635 155.748 82.8912L155.808 75.5453C157.201 75.7379 158.574 75.7313 159.953 75.7778C159.867 75.8575 159.913 75.9239 160.345 75.9239L163.853 80.9452C163.92 81.0448 163.979 81.0979 164.046 81.1909C169.153 88.384 175.095 95.4178 182.982 95.4178C184.547 95.4178 184.547 95.1654 184.547 94.8532" />
    </svg>
  )
}
