import { css } from '@emotion/react'
import { darken } from 'polished'
import { ComponentPropsWithoutRef } from 'react'

import { aspectRatio } from '@/theme/mixins'
import { colors } from '@/theme/variables'

interface Props extends ComponentPropsWithoutRef<'div'> {
  value: boolean
}

export const RadioSwitch = ({
  value,
  ...props
}: Props): JSX.Element => {
  const styles = {
    container: css`
      position: relative;
      width: 1.75em;
      height: 1em;
      padding: 0.075em;
      box-sizing: border-box;
      border-radius: calc(0.5em + 1px);
      border: 1px solid transparent;
      transition:
        background-color 200ms ease,
        border-color 200ms ease;
      display: flex;
      align-items: center;
      justify-content: flex-start;
      &:before {
        content: '';
        transition: flex-grow 200ms ease-out;
      }
      ${value === true &&
      css`
        background: ${colors.red};
        border-color: ${darken(0.05, colors.red)};
        &:before {
          flex-grow: 1;
        }
      `}
      ${value === false &&
      css`
        background: ${colors.gray85};
        border-color: ${darken(0.05, colors.gray85)};
        &:before {
          flex-grow: 0;
        }
      `}
    `,
    switch: css`
      flex: none;
      display: block;
      height: 100%;
      ${aspectRatio(1)}
      background: #fff;
      border-radius: 50%;
    `,
  }
  return (
    <div
      css={styles.container}
      {...props}
    >
      <span css={styles.switch} />
    </div>
  )
}
