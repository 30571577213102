import { css } from '@emotion/react'
import { ComponentPropsWithoutRef, Fragment } from 'react'

import { bezier } from '@/theme/mixins'
import { colors } from '@/theme/variables'

import { ExternalLink } from './ExternalLink'
import { FormLink } from './FormLink'
import { PageLink } from './PageLink'
import { PdfLink } from './PdfLink'
import { TertiaryArticleLink } from './TertiaryArticleLink'

export type DatoLinkData =
  | Queries.PageLinkFragment
  | Queries.ExternalLinkFragment
  | Queries.TertiaryArticleLinkFragment
  | Queries.PdfLinkFragment
  | Queries.FormLinkFragment
  | null

interface Props extends ComponentPropsWithoutRef<'a'> {
  data?:
    | Queries.PageLinkFragment
    | Queries.ExternalLinkFragment
    | Queries.TertiaryArticleLinkFragment
    | Queries.PdfLinkFragment
    | Queries.FormLinkFragment
    | null
  showIcon?: boolean
  styleVariant?: 'LOWERCASE' | 'BUTTON'
  disableLightboxLinks?: boolean
}

export const DatoLink = ({
  data,
  showIcon,
  styleVariant,
  disableLightboxLinks,
  ...props
}: Props): JSX.Element => {
  const styles = {
    link: css`
      position: relative;
      color: var(--link-color, currentColor);
      --font-weight: 500;
      font-weight: var(--font-weight);
      display: block;
      max-width: fit-content;
      padding: 0.5em 0;
      margin: 0.5em 0;
      text-transform: uppercase;
      letter-spacing: 0.025em;
      text-decoration: none;
      line-height: 1;

      transition: all 200ms ease;

      > span {
        position: relative;
      }
      ${showIcon &&
      css`
        padding-right: 1em;
      `}
      ${styleVariant === 'LOWERCASE' &&
      css`
        text-transform: none;
        letter-spacing: 0;
        margin: 0.25em 0;
      `}
      ${styleVariant === 'BUTTON' &&
      css`
        padding: 0.75em ${showIcon ? '1.5em' : '0.75em'} 0.75em 0.75em;
        margin: 1.5em 0 1.5em;
        border: 2px solid var(--link-color, currentColor);
        &:before {
          content: '';
          position: absolute;
          height: 100%;
          width: 0%;
          top: 0;
          left: 0;
          background: var(--button-hover-bg-color, ${colors.red});
          transition: width 200ms ${bezier.easeOut};
        }
        @media (hover: hover) {
          &&:hover {
            color: var(--button-hover-color, #fff);
            border-color: var(--button-hover-bg-color, ${colors.red});
            &:before {
              width: 100%;
            }
            ${showIcon &&
            css`
              && svg {
                transform: translate3d(0, 0, 0);
              }
            `}
          }
        }
      `}
    `,
  }

  switch (data?.__typename) {
    case 'DatoCmsPageLink':
      return (
        <PageLink
          data={data}
          showIcon={showIcon}
          css={styles.link}
          {...props}
        />
      )
    case 'DatoCmsExternalLink':
      return (
        <ExternalLink
          data={data}
          showIcon={showIcon}
          css={styles.link}
          {...props}
        />
      )
    case 'DatoCmsTertiaryArticleLink':
      return (
        <TertiaryArticleLink
          data={data}
          showIcon={showIcon}
          css={styles.link}
          disableLightboxLinks={disableLightboxLinks}
          {...props}
        />
      )
    case 'DatoCmsPdfLink':
      return (
        <PdfLink
          data={data}
          showIcon={showIcon}
          css={styles.link}
          {...props}
        />
      )
    case 'DatoCmsFormLink':
      return (
        <FormLink
          data={data}
          showIcon={showIcon}
          css={styles.link}
          disableLightboxLinks={disableLightboxLinks}
          {...props}
        />
      )
    default:
      return <Fragment />
  }
}
