import { graphql, useStaticQuery } from 'gatsby'

export const useMatriculationSchoolsQuery = () => {
  const { allDatoCmsMatriculationSchool } =
    useStaticQuery<Queries.useMatriculationSchoolsQuery>(graphql`
      query useMatriculationSchools {
        allDatoCmsMatriculationSchool(
          sort: [{ numberMatriculated: DESC }, { schoolName: ASC }]
        ) {
          nodes {
            ...MatriculationSchool
          }
        }
      }
    `)

  return allDatoCmsMatriculationSchool.nodes
}
