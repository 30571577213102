import { css, keyframes } from '@emotion/react'
import { graphql, useStaticQuery } from 'gatsby'
import { Link } from 'gatsby-link'
import { ComponentPropsWithoutRef, useMemo } from 'react'
import { UseSiteSearchData } from 'react-datocms/use-site-search'

import { bezier } from '@/theme/mixins'
import { colors } from '@/theme/variables'

import { useSearchContext } from '../../contexts/searchContext'

export type PageResultData = UseSiteSearchData['pageResults'][number]

interface Props extends ComponentPropsWithoutRef<'a'> {
  data?: PageResultData | null
}

export const SearchResult = ({
  data,
  ...props
}: Props): JSX.Element => {
  const { datoCmsSite } = useStaticQuery<Queries.SearchResultQuery>(
    graphql`
      query SearchResult {
        datoCmsSite {
          globalSeo {
            titleSuffix
          }
        }
      }
    `
  )
  const titleSuffix = datoCmsSite?.globalSeo?.titleSuffix
  const title = data?.raw.attributes.title.replace(
    titleSuffix || '',
    ''
  )
  const { setSearchIsOpen } = useSearchContext()
  const url = useMemo(() => {
    const urlObject = data?.url && new URL(data?.url)
    return urlObject && urlObject.pathname
  }, [data])
  const animation = keyframes`
    0% {
      opacity: 0;
    }
    100% {
      opacity: 1;
    }
  `
  const styles = {
    link: css`
      position: relative;
      text-decoration: none;
      color: ${colors.gray20};
      padding: 0.25em 0.5em 0.5em calc(1.5em + 2px);
      opacity: 0;
      animation: ${animation} 500ms ease forwards 300ms;
      &:after {
        content: '';
        position: absolute;
        left: 0;
        top: 0;
        height: 100%;
        width: 2px;
        background: ${colors.gray85};
        transition: background-color 500ms ${bezier.easeOut};
      }
      &:before {
        content: '';
        position: absolute;
        left: -1rem;
        top: -1rem;
        width: calc(100% + 2rem);
        height: calc(100% + 2rem);
        transition: background-color 500ms ${bezier.easeOut};
      }
      @media (hover: hover) {
        &:hover {
          outline: none;
          &:after {
            background: ${colors.red};
          }
          &:before {
            background: ${colors.gray95};
          }
        }
      }
      &:focus {
        outline: none;
        &:after {
          background: ${colors.red};
        }
        &:before {
          background: ${colors.gray95};
        }
      }
    `,
    title: css`
      position: relative;
      font-size: var(--fs-48);
      margin: 0;
      line-height: 1;
    `,
    excerpt: css`
      position: relative;
      font-size: var(--fs-15);
      line-height: 1.5;
      mark {
        background: #0000000c;
        color: ${colors.red};
        font-weight: 500;
      }
    `,
    url: css`
      position: relative;
      font-size: var(--fs-14);
      color: ${colors.gray65};
      margin: 0.5em 0.125em;
    `,
  }
  return (
    <Link
      css={styles.link}
      to={url || ''}
      onClick={() => setSearchIsOpen(false)}
      {...props}
    >
      <h2 css={styles.title}>{title}</h2>
      <div css={styles.url}>{url !== '/' ? url : 'Home page'}</div>
    </Link>
  )
}
