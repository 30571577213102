import { css } from '@emotion/react'
import { PageProps } from 'gatsby'
import { Fragment, ReactElement, useState } from 'react'
import { Transition, TransitionGroup } from 'react-transition-group'

import { Seal } from '@/features/brearley-logo'
import { GlobalStyles } from '@/theme/GlobalStyles'

import { Alert } from '../Alert'
import { Footer } from '../Footer'
import { MainNav } from '../MainNav'

type Props = {
  children: ReactElement
  pageProps: PageProps & {
    pageContext: {
      hideFooter?: boolean
      hideNav?: boolean
      shortNav?: boolean
    }
  }
}

export const Layout = ({
  pageProps: { location, pageContext },
  children,
}: Props): JSX.Element => {
  const { hideFooter, hideNav, shortNav } = pageContext

  const transitionTimeout = 400

  const [transitioning, setTransitioning] = useState<
    boolean | undefined
  >()
  const styles = {
    main: css`
      display: grid;
    `,
    transitionWrapper: css`
      position: relative;
      grid-column: 1 / 2;
      grid-row: 1 / 2;
      opacity: 0;
      transition: opacity ${transitionTimeout * 0.25}ms ease-in-out;
      &[data-status='entered'] {
        transition-duration: ${transitionTimeout * 0.75}ms;
        opacity: 1;
        z-index: 1;
      }
      &[data-status='exiting'] {
        opacity: 0;
      }
    `,
    seal: css`
      position: fixed;
      pointer-events: none;
      width: 100%;
      height: 100%;
      opacity: ${transitioning ? 0.125 : 0};
      transition: opacity ${transitionTimeout * 0.25}ms ease-in-out;
      display: flex;
      justify-content: center;
      align-items: center;
      > img {
        min-width: 33%;
        width: 24ch;
        max-width: 90vw;
        max-height: 90svh;
      }
    `,
    footer: css`
      transition: opacity ${transitionTimeout * 0.75}ms ease-in-out;
      opacity: ${transitioning ? 0 : 1};
    `,
  }

  return (
    <Fragment>
      <GlobalStyles />
      <div css={styles.seal}>
        <Seal color="#fff" />
      </div>
      <Alert data-datocms-noindex />
      {!hideNav && (
        <MainNav
          shortNav={shortNav}
          data-datocms-noindex
        />
      )}
      <TransitionGroup
        component={'main'}
        css={styles.main}
        // appear
      >
        <Transition
          key={location.pathname}
          timeout={transitionTimeout}
          onEntered={() => setTransitioning(false)}
          onExiting={() => setTransitioning(true)}
        >
          {status => {
            return (
              <div
                css={styles.transitionWrapper}
                data-status={status}
              >
                {children}
              </div>
            )
          }}
        </Transition>
      </TransitionGroup>
      {!hideFooter && (
        <Footer
          css={styles.footer}
          data-datocms-noindex
        />
      )}

      <div id="lightbox-container" />
    </Fragment>
  )
}
