import { css } from '@emotion/react'
import { graphql } from 'gatsby'
import { ComponentPropsWithoutRef } from 'react'

interface Props extends ComponentPropsWithoutRef<'div'> {
  data?: Queries.AwardSpecialHeadingFragment | null
  showYear: boolean
}

export const AwardSpecialHeading = ({
  data,
  showYear,
  ...props
}: Props): JSX.Element => {
  const styles = {
    container: css`
      display: inline-grid;
      grid-template-columns: 5ch 1fr;
      font-size: var(--fs-18);
      width: 100%;
      margin: 0 0 0.5em;
    `,
    awardYear: css`
      grid-column: 1 / 2;
      grid-row: 1 / 3;
      line-height: 1.333;
      font-weight: 600;
    `,
    heading: css`
      grid-column: 2 / 3;
      margin: 0;
      font-family: var(--ff-body);
      line-height: 1.333;
      font-style: italic;
      font-weight: 600;
      ${!showYear &&
      css`
        visibility: hidden;
      `}
    `,
  }
  return (
    <div
      css={styles.container}
      {...props}
    >
      <div
        css={styles.awardYear}
        aria-hidden={!showYear}
      >
        {data?.awardYear}
      </div>
      <h4 css={styles.heading}>{data?.heading}</h4>
    </div>
  )
}

export const AwardSpecialHeadingFragment = graphql`
  fragment AwardSpecialHeading on DatoCmsAwardSpecialHeading {
    id: originalId
    __typename
    heading
    awardYear
  }
`
