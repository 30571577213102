import { css } from '@emotion/react'
import { graphql } from 'gatsby'
import { ComponentPropsWithoutRef } from 'react'

import { mq } from '@/theme/mixins'
import { colors } from '@/theme/variables'

import { bodyGrid, bodyHeading } from '../../styles'
import { Alumna } from './Alumna'
import { AlumnaeGroup } from './AlumnaeGroup'

interface Props extends ComponentPropsWithoutRef<'section'> {
  data?: Queries.AlumnaeSectionFragment | null
  bgColor: 'WHITE' | 'GRAY'
}

export const AlumnaeSection = ({
  data,
  bgColor,
  ...props
}: Props): JSX.Element => {
  const styles = {
    section: css`
      ${bodyGrid}
      background: ${bgColor === 'WHITE'
        ? '#fff'
        : bgColor === 'GRAY' && colors.gray95};
    `,
    heading: css`
      ${bodyHeading}
    `,
    alumnae: css`
      columns: 3;
      gap: 3em;
      list-style: none;
      margin: 0;
      padding: 0;
      ${mq().s} {
        columns: 2;
      }
    `,
    group: css`
      ${mq().s} {
        column-span: all;
      }
    `,
  }
  return (
    <section
      css={styles.section}
      {...props}
    >
      <h2 css={styles.heading}>{data?.heading}</h2>
      <ul css={styles.alumnae}>
        {data?.alumnae?.map((block, i) => {
          switch (block?.__typename) {
            case 'DatoCmsAlumna':
              return (
                <li key={i}>
                  <Alumna
                    data={block}
                    headingLevel={3}
                  />
                </li>
              )
            case 'DatoCmsAlumnaeGroup':
              return (
                <li
                  key={i}
                  css={styles.group}
                >
                  <AlumnaeGroup data={block} />
                </li>
              )
          }
        })}
      </ul>
    </section>
  )
}

export const AlumnaeSectionFragment = graphql`
  fragment AlumnaeSection on DatoCmsAlumnaeSection {
    __typename
    id: originalId
    heading
    alumnae {
      ... on DatoCmsAlumna {
        ...Alumna
      }
      ... on DatoCmsAlumnaeGroup {
        ...AlumnaeGroup
      }
    }
  }
`
