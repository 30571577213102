import { css } from '@emotion/react'
import { useEffect } from 'react'
import { useInView } from 'react-intersection-observer'

import { mq } from '@/theme/mixins'
import { colors } from '@/theme/variables'

import { useMissionScrollContext } from '../../contexts/missionScrollContext'
import { TextBlock } from './TextBlock'

interface Props {
  textBlock?: Queries.MissionTextFragment | null
}

export const Text5 = ({ textBlock }: Props): JSX.Element => {
  const { addViewZone, removeViewZone } = useMissionScrollContext()
  const { inView: inView19, ref: ref19 } = useInView()
  const { inView: inView20, ref: ref20 } = useInView()
  const { inView: inView21, ref: ref21 } = useInView()
  useEffect(() => {
    inView19 ? addViewZone(19) : removeViewZone(19)
    inView20 ? addViewZone(20) : removeViewZone(20)
    inView21 ? addViewZone(21) : removeViewZone(21)
  }, [inView19, inView20, inView21, addViewZone, removeViewZone])
  const styles = {
    section: css`
      box-sizing: border-box;
      display: grid;
      padding: 0 var(--margin);
      justify-items: center;
      color: #000;
    `,
    zone: css`
      grid-column: 1 / -1;
      height: max(8rem, 16vh);
    `,
    blockWrap: css`
      grid-column: 1 / -1;
      display: flex;
      min-height: 100vh;
      align-items: center;
      justify-content: center;
      padding: 5em var(--margin);
      box-sizing: border-box;
    `,
    textBlock: css`
      text-align: center;
      max-width: 30ch;
      grid-column: 1 / -1;
      padding: 0 var(--margin) 80vh;
      ${mq().s} {
        text-align: left;
        max-width: 100%;
      }
      a {
        @media (hover: hover) {
          &:hover {
            color: ${colors.red};
          }
        }
      }
    `,
  }
  return (
    <section css={styles.section}>
      <div
        ref={ref19}
        css={styles.zone}
      />
      <div
        ref={ref20}
        css={styles.zone}
      />
      <TextBlock
        ref={ref21}
        textBlock={textBlock}
        css={styles.textBlock}
        decoColor={colors.red}
      />
    </section>
  )
}
