import { css } from '@emotion/react'
import { graphql } from 'gatsby'
import { ComponentPropsWithoutRef, ElementType } from 'react'

import { DirectoryPerson } from '@/features/directory'
import { firstChild } from '@/theme/mixins'

interface Props extends ComponentPropsWithoutRef<'div'> {
  data: Queries.FacultySubsectionFragment | null
  headingLevel?: number
}

export const FacultySubsection = ({
  data,
  headingLevel = 3,
  ...props
}: Props): JSX.Element => {
  const Heading = `h${headingLevel}` as ElementType
  const styles = {
    section: css``,
    heading: css`
      font-size: var(--fs-36);
      margin-top: 1.5em;
      padding-bottom: 0.5em;
      border-bottom: 1px solid rgba(0, 0, 0, 0.2);
      ${firstChild} > & {
        margin-top: 0;
      }
    `,
    faculty: css`
      display: grid;
      grid-template-columns: 1fr 1fr;
      gap: var(--gtr-24);
    `,
  }
  return (
    <section
      css={styles.section}
      {...props}
    >
      <Heading css={styles.heading}>{data?.heading}</Heading>
      <div css={styles.faculty}>
        {data?.faculty?.map(person => (
          <DirectoryPerson
            data={person}
            key={person?.id}
            layout="SUBSECTION"
            showHeadshot={data.showHeadshots || false}
          />
        ))}
      </div>
    </section>
  )
}

export const FacultySubsectionFragment = graphql`
  fragment FacultySubsection on DatoCmsFacultySubsection {
    __typename
    id: originalId
    heading
    showHeadshots
    faculty {
      ...DirectoryFacultyStaffMember
    }
  }
`
