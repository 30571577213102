import { ReactNode } from 'react'

import { MainNavContextProvider } from '@/features/layout'
import { MissionScrollContextProvider } from '@/features/mission-sections'
import { SearchContextProvider } from '@/features/search'

export const ContextWrapper = ({
  children,
}: {
  children: ReactNode
}): JSX.Element => {
  return (
    <MissionScrollContextProvider>
      <MainNavContextProvider>
        <SearchContextProvider>{children}</SearchContextProvider>
      </MainNavContextProvider>
    </MissionScrollContextProvider>
  )
}
