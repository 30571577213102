import { Link, graphql } from 'gatsby'
import { ComponentPropsWithoutRef, Fragment, ReactNode } from 'react'

import { useTertiaryArticleQuery } from '@/features/dato-link'
import { LightboxLink } from '@/features/lightbox'

import { DatoLinkIcon } from './DatoLinkIcon'

interface Props extends ComponentPropsWithoutRef<'a'> {
  data?:
    | Queries.TertiaryArticleLinkFragment
    | {
        linkText: string | ReactNode
        article: {
          slug: string
        }
      }
    | null

  showIcon?: boolean
  disableLightboxLinks?: boolean
}

export const TertiaryArticleLink = ({
  data,
  showIcon,
  disableLightboxLinks,
  ...props
}: Props): JSX.Element => {
  // Need to use a separate query here to avoid an infinite loop.
  // Might not work, could still infinite loop. Check later.
  const article = useTertiaryArticleQuery(data?.article?.slug)
  if (article) {
    if (disableLightboxLinks) {
      return (
        <Link
          to={`/${article.slug}/`}
          {...props}
        >
          <span>{data?.linkText}</span>
          {showIcon && <DatoLinkIcon iconType="LIGHTBOX" />}
        </Link>
      )
    } else {
      return (
        <LightboxLink
          data={article}
          {...props}
        >
          <span>{data?.linkText}</span>
          {showIcon && <DatoLinkIcon iconType="LIGHTBOX" />}
        </LightboxLink>
      )
    }
  } else {
    return <Fragment />
  }
}

export const TertiaryArticleLinkFragment = graphql`
  fragment TertiaryArticleLink on DatoCmsTertiaryArticleLink {
    __typename
    id: originalId
    linkText
    article {
      slug
    }
  }
`
