import { css } from '@emotion/react'
import { graphql } from 'gatsby'
import { darken, lighten } from 'polished'
import { ComponentPropsWithoutRef } from 'react'

import { mq } from '@/theme/mixins'
import { colors } from '@/theme/variables'

import { HighlightSectionImage } from './HighlightSectionImage'
import { HighlightSectionMedia } from './HighlightSectionMedia'
import { HighlightSectionText } from './HighlightSectionText'

interface SharedProps extends ComponentPropsWithoutRef<'section'> {
  data?:
    | Queries.HighlightSectionFragment
    | Queries.HighlightMultimediaSectionFragment
    | null
  textAlignment: 'LEFT' | 'RIGHT'
}

interface DefaultProps extends SharedProps {
  bgColor: 'WHITE' | 'GRAY'
  specialBgColor?: undefined
}

interface SpecialProps extends SharedProps {
  specialBgColor: 'WHITE' | 'DARK' | 'RED'
  bgColor?: undefined
}

type Props = SpecialProps | DefaultProps

export const HighlightSection = ({
  data,
  textAlignment,
  bgColor,
  specialBgColor,
  ...props
}: Props): JSX.Element => {
  const getSpecialColorHex = () => {
    switch (specialBgColor) {
      case 'DARK':
        return colors.gray20
      case 'RED':
        return colors.red
      case 'WHITE':
        return '#fff'
    }
  }
  const specialBgColorHex = getSpecialColorHex() as string

  const styles = {
    section: css`
      position: relative;
      background: ${bgColor === 'GRAY' ? colors.gray95 : '#fff'};
      display: grid;
      grid-template-columns: 1fr 1fr;
      gap: var(--gtr-36);
      padding: var(--row-36) var(--margin);
      ${data?.__typename === 'DatoCmsHighlightMultimediaSection' &&
      css`
        grid-template-columns: ${textAlignment === 'LEFT'
          ? '5fr 7fr'
          : textAlignment === 'RIGHT' && '7fr 5fr'};
      `}
      ${mq().ms} {
        grid-template-columns: 1fr;
        grid-row-gap: 1rem;
      }
      ${mq().s} {
        padding: var(--row-36) 0.75rem;
        grid-row-gap: 0.5rem;
      }
      ${specialBgColor &&
      css`
        background-color: ${darken(0.15, specialBgColorHex)};
        padding: 0 var(--margin);
        gap: 0;
        &:before,
        &:after {
          content: '';
          position: absolute;
          grid-column-end: span 1;
          grid-column-start: ${textAlignment === 'LEFT' ? 2 : 1};
          width: calc(1.5 * var(--margin));
          height: 100%;
          justify-self: ${textAlignment === 'LEFT'
            ? 'flex-start'
            : 'flex-end'};
        }
        &:before {
          background-color: ${darken(0.09, specialBgColorHex)};
        }
        &:after {
          background-color: ${lighten(0.0333, specialBgColorHex)};
          transform: translateX(
            ${textAlignment === 'LEFT' ? -50 : 50}%
          );
        }
        ${mq().ms} {
          &:before,
          &:after {
            display: none;
          }
        }
        ${mq().s} {
          padding: 0 0.5rem;
        }
      `}
    `,
  }
  return (
    <section
      css={styles.section}
      {...props}
    >
      {data?.__typename === 'DatoCmsHighlightSection' && (
        <HighlightSectionImage
          data={data?.image}
          textAlignment={textAlignment}
          specialBgColor={specialBgColor}
        />
      )}
      {data?.__typename === 'DatoCmsHighlightMultimediaSection' && (
        <HighlightSectionMedia
          data={data?.media}
          textAlignment={textAlignment}
        />
      )}
      <HighlightSectionText
        data={data}
        textAlignment={textAlignment}
        color={bgColor}
        specialBgColor={specialBgColor}
      />
    </section>
  )
}

export const HighlightSectionFragments = graphql`
  fragment HighlightSection on DatoCmsHighlightSection {
    id: originalId
    __typename
    image {
      defaultImageData: gatsbyImageData(
        width: 720
        sizes: "(max-width: 720px) 100vw, 50vw"
        imgixParams: { ar: "1:1", fit: "crop", crop: "focalpoint" }
      )
      specialImageData: gatsbyImageData(
        width: 720
        sizes: "(max-width: 720px) 100vw, 50vw"
        imgixParams: {
          ar: "1:1"
          fit: "crop"
          crop: "focalpoint"
          sat: -100
          con: 5
        }
      )
      ...ImageFocalData
    }
    heading
    body {
      ...BodyTextBlockShort
    }
    linksList: links {
      ...LinksList
    }
  }
  fragment HighlightMultimediaSection on DatoCmsHighlightMultimediaSection {
    id: originalId
    __typename
    media {
      ... on DatoCmsImageBlock {
        __typename
        id: originalId
        image {
          gatsbyImageData(
            width: 1280
            sizes: "(max-width: 720px) 100vw, 67vw"
            imgixParams: { ar: "3:2", fit: "crop", crop: "focalpoint" }
          )
          ...ImageFocalData
        }
      }
      ... on DatoCmsExternalVideoBlock {
        ...ExternalVideoBlock
      }
      ... on DatoCmsMediaCarousel {
        ...MediaCarousel
      }
    }
    heading
    body {
      ...BodyTextBlockShort
    }
    linksList: links {
      ...LinksList
    }
  }
`
